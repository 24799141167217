import { apiService } from '../../../services/api-service';
import { getStaticAssetPath } from '../../../utils/assets';
import { type MediaItem, type MediaProvider } from '../MediaProvider';

const GIPHY_LOGO = getStaticAssetPath('images/giphy-logo.png');

export const GiphyProvider: MediaProvider = {
  name: 'Giphy',
  icon: <img src={GIPHY_LOGO} alt='Giphy logo' className='w-8 h-8' />,
  async searchMedia(query: string, offset = 0): Promise<MediaItem[]> {
    const response = await apiService.media.searchExternalMedia({
      provider: 'giphy',
      q: query,
      offset,
      size: 25,
    });

    return response.data.items;
  },
};
