import { useCallback, useEffect, useRef, useState } from 'react';

import { type Media } from '@lp-lib/media';

import spinner from '../../assets/img/loading-spinner.png';
import { useDebouncedValue } from '../../hooks/useDebouncedValue';
import { getLogger } from '../../logger/logger';
import { err2s } from '../../utils/common';
import { SearchIcon } from '../icons/SearchIcon';
import { TimerIcon } from '../icons/TimerIcon';
import { useMediaUploader } from '../MediaUploader/useMediaUploader';
import { type MediaItem, type MediaProvider } from './MediaProvider';
import { mediaProviders } from './MediaProviders';

const logger = getLogger();

export function InlineLoadingSpinner(props: { isUploading: boolean }) {
  if (!props.isUploading) return null;

  return (
    <div className='flex items-center gap-2'>
      <img src={spinner} alt='spinner' className='w-5 h-5 animate-spin' />
      <span className='text-white text-sm'>Uploading...</span>
    </div>
  );
}

async function fetchAndCreateFile(
  url: string,
  fileName: string
): Promise<File> {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch media from external source');
  }

  const blob = await response.blob();

  const mimeToExt: { [key: string]: string } = {
    'image/gif': '.gif',
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'video/mp4': '.mp4',
    'video/webm': '.webm',
  };

  const extension = mimeToExt[blob.type] || '';
  const finalFileName = fileName.endsWith(extension)
    ? fileName
    : `${fileName}${extension}`;

  return new File([blob], finalFileName, { type: blob.type });
}

const MediaSearchModal = ({
  video = false,
  onUploadSuccess,
}: {
  video?: boolean;
  onUploadSuccess: (media: Media) => void;
}) => {
  const [activeProvider, setActiveProvider] = useState<MediaProvider>(
    mediaProviders[0]
  );
  // Handling this as state produces undesired behavior due to the modal closing after uploading.
  // Also, this will probably be moved to the backend in the future.
  const recentMedia = (() => {
    const storedRecent = localStorage.getItem('recentMedia');
    if (storedRecent) {
      try {
        const parsed = JSON.parse(storedRecent);
        return Array.isArray(parsed) ? parsed : [];
      } catch (e) {
        logger.error('Failed to parse recentMedia from localStorage', e);
        localStorage.removeItem('recentMedia'); // Clear corrupted data
        return [];
      }
    }
    return [];
  })();

  const [searchQuery, setSearchQuery] = useState('');
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isExternalUploading, setIsExternalUploading] = useState(false);
  const [recentIsActive, setRecentIsActive] = useState(false);

  const updateRecentMedia = (newItem: Media) => {
    const storedRecent = localStorage.getItem('recentMedia');
    let recentItems: Media[] = [];

    if (storedRecent) {
      try {
        recentItems = JSON.parse(storedRecent);
        if (!Array.isArray(recentItems)) {
          recentItems = [];
        }
      } catch (e) {
        logger.error('Failed to parse recentMedia from localStorage', e);
        localStorage.removeItem('recentMedia'); // Clear corrupted data
        recentItems = [];
      }
    }

    recentItems = recentItems.filter((item) => item.id !== newItem.id);
    recentItems.unshift(newItem);
    // Limit the recent media to 25.
    const updatedRecent = recentItems.slice(0, 25);

    localStorage.setItem('recentMedia', JSON.stringify(updatedRecent));
  };

  const isFetchingMoreRef = useRef(isFetchingMore);

  useEffect(() => {
    isFetchingMoreRef.current = isFetchingMore;
  }, [isFetchingMore]);

  const { inputElement, isUploading, uploadError, addFile } = useMediaUploader({
    image: true,
    video,
    onUploadSuccess: (uploadedMedia) => {
      updateRecentMedia(uploadedMedia);
      onUploadSuccess(uploadedMedia);
    },
  });

  const isLoading = isUploading || isExternalUploading;

  const handleMediaSelect = async (item: MediaItem) => {
    try {
      setIsExternalUploading(true);

      const file = await fetchAndCreateFile(
        item.url,
        `external-${item.id}-${item.title || 'media'}`
      );

      addFile({
        name: file.name,
        type: file.type,
        data: file,
        source: 'External Source',
        isRemote: false,
      });
    } catch (error) {
      logger.error('Failed to process external media:', error);
    } finally {
      setIsExternalUploading(false);
    }
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const debouncedSearchQuery = useDebouncedValue(searchQuery, {
    settleAfterMs: 500,
  });

  const loadMedia = useCallback(
    async (startOffset: number, query: string) => {
      if (isFetchingMoreRef.current || query.trim() === '') return;

      if (startOffset === 0) {
        setIsSearching(true);
      } else {
        setIsFetchingMore(true);
      }

      try {
        const results = await activeProvider.searchMedia(query, startOffset);
        if (results.length > 0) {
          setMediaItems((prevItems) =>
            startOffset === 0 ? results : [...prevItems, ...results]
          );
          setOffset(startOffset + results.length);
          setHasMore(results.length >= 25);
        } else {
          if (startOffset === 0) {
            setMediaItems([]);
          }
          setHasMore(false);
        }
      } catch (error) {
        logger.error('Error fetching media:', error);
        setHasMore(false);
      } finally {
        if (startOffset === 0) {
          setIsSearching(false);
        } else {
          setIsFetchingMore(false);
        }
      }
    },
    [activeProvider]
  );

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      setIsSearching(true);
    } else {
      setIsSearching(false);
      setMediaItems([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!activeProvider) return;
    if (
      debouncedSearchQuery.isSettling ||
      debouncedSearchQuery.isInitializing
    ) {
      // Do not perform search while the value is settling or initializing
      return;
    }

    if (
      debouncedSearchQuery.value &&
      debouncedSearchQuery.value.trim() !== ''
    ) {
      loadMedia(0, debouncedSearchQuery.value);
    } else {
      setMediaItems([]);
      setOffset(0);
      setHasMore(false);
      setIsSearching(false);
    }
  }, [debouncedSearchQuery, activeProvider, loadMedia]);

  const handleScroll = () => {
    if (isFetchingMore || !hasMore || isSearching) return;

    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 100 &&
        debouncedSearchQuery.value
      ) {
        loadMedia(offset, debouncedSearchQuery.value);
      }
    }
  };

  const handleRecentMediaSelect = async (item: Media) => {
    try {
      setIsExternalUploading(true);
      onUploadSuccess(item);
      updateRecentMedia(item);
    } catch (error) {
      logger.error('Failed to select recent media:', error);
    } finally {
      setIsExternalUploading(false);
    }
  };

  return (
    <div className='flex w-full h-full'>
      <div className='w-64 border-r border-secondary flex flex-col'>
        <div className='flex-1'>
          <h3
            className={`mb-2 flex items-center gap-2 p-4 ${
              isLoading ? 'opacity-50' : 'hover:text-white hover:cursor-pointer'
            } ${
              recentIsActive
                ? 'text-white bg-main-layer rounded-tl-xl'
                : 'text-gray-400'
            }`}
            onClick={() => setRecentIsActive(true)}
          >
            <TimerIcon className='w-4 h-4 fill-current' />
            Recent
          </h3>
          <h3 className='text-gray-400 mb-2 px-4'>Media Source</h3>
          <div className='mt-6'>
            {mediaProviders.map((provider) => (
              <div
                key={provider.name}
                className={`flex items-center gap-3 p-3 pl-5 ${
                  activeProvider.name === provider.name && !recentIsActive
                    ? 'bg-main-layer w-full'
                    : ''
                } ${
                  isLoading
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-gray-800 cursor-pointer'
                }`}
                onClick={() => {
                  if (!isLoading) {
                    setRecentIsActive(false);
                    setActiveProvider(provider);
                  }
                }}
              >
                {provider.icon}
                <span className='text-white'>{provider.name}</span>
              </div>
            ))}
          </div>
        </div>

        <div className='p-6'>
          <label
            className={`w-full border border-secondary text-white rounded-lg p-3 flex items-center justify-center gap-2 bg-main-layer ${
              isLoading
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-gray-700 cursor-pointer'
            }`}
          >
            {isLoading ? (
              <InlineLoadingSpinner isUploading={true} />
            ) : (
              <>
                Upload Media
                <div className='hidden'>{inputElement}</div>
              </>
            )}
          </label>
          {uploadError && (
            <div className='w-full h-4'>
              <p className='mt-1 text-xs text-red-500'>{err2s(uploadError)}</p>
            </div>
          )}
          {/* <div
            className={`w-full mt-2 text-secondary flex items-center justify-center gap-2 text-sms ${
              isLoading
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:text-white hover:cursor-pointer'
            }`}
          >
            Select with AI
            <AIIcon className='w-4 h-4 fill-current' />
          </div> */}
        </div>
      </div>

      <div className='flex-1 p-6 flex flex-col'>
        <div className='flex items-center mb-6'>
          <h2 className='text-2xl text-white font-semibold'>Add Media</h2>
        </div>

        {recentIsActive ? (
          // Render Recent Media
          <div className='flex-1 overflow-y-auto' ref={scrollContainerRef}>
            {recentMedia.length > 0 ? (
              <div className='grid grid-cols-3 gap-4'>
                {recentMedia.map((item) => (
                  <div
                    key={item.id}
                    className={`aspect-video bg-gray-800 rounded-lg ${
                      isLoading
                        ? 'opacity-50 cursor-not-allowed'
                        : 'cursor-pointer hover:opacity-75'
                    }`}
                    onClick={() => !isLoading && handleRecentMediaSelect(item)}
                  >
                    <img
                      src={item.lastThumbnailUrl ?? item.url}
                      className='w-full h-full object-cover rounded-lg'
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className='text-center text-gray-500'>
                No recent media. Please select or upload media.
              </div>
            )}
          </div>
        ) : (
          <>
            <div className='relative mb-6'>
              <input
                type='text'
                placeholder={`Search ${activeProvider.name}`}
                value={searchQuery}
                onChange={(e) => !isLoading && setSearchQuery(e.target.value)}
                disabled={isLoading}
                className={`w-full bg-black border border-secondary rounded-lg px-4 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              />
              <SearchIcon
                className={`absolute right-2 top-1/2 -translate-y-1/2 text-icon-gray bg-secondary w-8 h-8 rounded-xl px-2 py-1 fill-current ${
                  isLoading ? 'opacity-50' : ''
                }`}
              />
            </div>

            <div
              className='flex-1 overflow-y-auto'
              ref={scrollContainerRef}
              onScroll={handleScroll}
            >
              {searchQuery.trim() !== '' &&
              (isSearching || debouncedSearchQuery.isSettling) ? (
                <div className='text-center text-white py-4'>
                  <span className='flex items-center justify-center gap-2'>
                    <img
                      src={spinner}
                      alt='spinner'
                      className='w-5 h-5 animate-spin'
                    />
                    <span>Loading...</span>
                  </span>
                </div>
              ) : mediaItems.length > 0 ? (
                <div className='grid grid-cols-3 gap-4'>
                  {mediaItems.map((item) => (
                    <div
                      key={item.id}
                      className={`aspect-video bg-gray-800 rounded-lg ${
                        isLoading
                          ? 'opacity-50 cursor-not-allowed'
                          : 'cursor-pointer hover:opacity-75'
                      }`}
                      onClick={() => !isLoading && handleMediaSelect(item)}
                    >
                      <img
                        src={item.thumbnailUrl}
                        alt={item.title}
                        className='w-full h-full object-cover rounded-lg'
                      />
                    </div>
                  ))}
                </div>
              ) : searchQuery.trim() !== '' ? (
                <div className='text-center text-gray-500'>
                  No results found.
                </div>
              ) : (
                <div className='text-center text-gray-500'>
                  Please enter a search query to find media.
                </div>
              )}

              {isFetchingMore && (
                <div className='text-center text-white py-4'>
                  Loading more...
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { MediaSearchModal };
