import {
  DndContext,
  type DragEndEvent,
  type DragMoveEvent,
  useDraggable,
} from '@dnd-kit/core';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { type ConnectDragSource } from 'react-dnd';
import {
  FormProvider,
  useFieldArray,
  type UseFieldArrayReturn,
  useForm,
  useWatch,
} from 'react-hook-form';
import { usePopperTooltip } from 'react-popper-tooltip';
import Select from 'react-select';
import { useKey } from 'react-use';
import useMeasure from 'react-use-measure';

import {
  EnumsHiddenPictureAsymmetricPinDropAudibility,
  EnumsHiddenPictureAsymmetricPinDropVisibility,
  EnumsHiddenPictureMode,
} from '@lp-lib/api-service-client/public';
import {
  assertExhaustive,
  type HiddenPicture,
  type HiddenPictureBlock,
  HotSpotShape,
  type HotSpotShapeData,
  type HotSpotV2,
} from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { fromMediaDTO } from '../../../utils/api-dto';
import { uuidv4 } from '../../../utils/common';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../../utils/media';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { Menu, MenuItem } from '../../common/ActionMenu';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { PointsInput } from '../../Game/Blocks/Common/Editor/PointsUtilities';
import { HiddenPictureUtils } from '../../Game/Blocks/HiddenPicture/utils';
import { CommonButton } from '../../GameV2/design/Button';
import { EditableText } from '../../GameV2/design/Editable';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { ImageIcon } from '../../icons/ImageIcon';
import { useTriggerMediaSearchModal } from '../../MediaSearch/useTriggerMediaSearchModal';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { BlockMusicSelect } from './BlockMusicSelect';
import { useBlockFieldController, useTrainingSlideEditor } from './hooks';
import { BlockIntroSelect } from './Shared/BlockIntroSelect';
import { type TrainingSlideEditorProps } from './types';

type HiddenPictureFormData = Omit<HiddenPicture, 'id'>;
type SelectedHotSpot = {
  id: string;
  ping: boolean;
  autoScroll: boolean;
};
type GutterPos = 'n' | 's' | 'w' | 'e';

export const ASPECT_RATIO = 16 / 9;
const HOT_SPOT_EDITOR_HEIGHT_PX = 88;
const HOT_SPOT_EDITOR_GAP_PX = 16;

export function HiddenPictureBlockEditor(
  props: TrainingSlideEditorProps<HiddenPictureBlock>
) {
  return <Left {...props} />;
}

export function HiddenPictureBlockSidebarEditor(
  props: TrainingSlideEditorProps<HiddenPictureBlock>
) {
  return <Right {...props} />;
}

type ModeOption = {
  value: EnumsHiddenPictureMode;
  label: string;
};

const modeOptions: ModeOption[] = [
  {
    value: EnumsHiddenPictureMode.HiddenPictureModeAll,
    label: 'Find All Items',
  },
  {
    value: EnumsHiddenPictureMode.HiddenPictureModeOne,
    label: 'Find One Item',
  },
];

export function ModeSelect(props: {
  value?: EnumsHiddenPictureMode;
  onChange: (value: EnumsHiddenPictureMode) => void;
}) {
  const selectedValue =
    modeOptions.find((option) => option.value === props.value) ??
    modeOptions[0];

  const styles = useMemo(() => buildReactSelectStyles<ModeOption>(), []);

  return (
    <Select<ModeOption>
      options={modeOptions}
      value={selectedValue}
      styles={styles}
      onChange={(newValue) => {
        if (newValue) {
          props.onChange(newValue.value);
        }
      }}
      classNamePrefix='select-box-v2'
      className='w-full text-white'
    />
  );
}

function EditableFieldText(
  props: TrainingSlideEditorProps<HiddenPictureBlock> & {
    field: 'instructions';
  }
) {
  const { value, updateLocal, updateRemote } = useBlockFieldController(
    props.block,
    'instructions'
  );

  return (
    <EditableText
      value={value ?? 'Type your instructions here.'}
      onBlur={(value) => {
        updateLocal(value);
        updateRemote(value);
      }}
    />
  );
}

function MediaField(props: TrainingSlideEditorProps<HiddenPictureBlock>) {
  const { value, updateLocal, updateRemote } = useBlockFieldController(
    props.block,
    'pictures'
  );

  const triggerMediaSearchModal = useTriggerMediaSearchModal();

  const handleUploadSuccess = (media: Media) => {
    const asset: HiddenPicture = {
      id: uuidv4(),
      name: uuidv4(),
      sequenced: false,
      everyoneClicks: false,
      hotSpotsV2: [],
      tool: 'none',
      mainMedia: media,
      mainMediaData: {
        id: media.id,
      },
      question: '',
      incorrectAnswerPenalty: 0,
      pinDropHidden: false,
      pinDropMuted: false,
      hidePointsAnimation: false,
      asymmetricGamePlay: false,
      asymmetricMedia: null,
      asymmetricMediaData: null,
    };
    updateLocal([asset]);
    updateRemote([asset]);
  };

  const openMediaSearchModal = () => {
    triggerMediaSearchModal({
      onUploadSuccess: handleUploadSuccess,
    });
  };

  const mainMedia = value ? value[0]?.mainMedia : null;

  const displayUrl = MediaUtils.PickMediaUrl(fromMediaDTO(mainMedia), {
    priority: ImagePickPriorityHighToLow,
  });

  return (
    <>
      {displayUrl ? (
        // Media preview
        <div
          className='w-full cursor-pointer aspect-w-16 aspect-h-9 group relative flex-1 min-h-0 flex flex-col items-center justify-center'
          onClick={openMediaSearchModal}
        >
          <img
            src={displayUrl}
            alt=''
            className='w-full h-full scale-x-150 scale-y-125 blur opacity-70 md:hidden'
          />
          <img src={displayUrl} alt='' className='w-full h-full object-fill' />
          <div className='absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity'>
            <button
              onClick={(e) => {
                e.stopPropagation();
                updateLocal(null);
                updateRemote(null);
              }}
              className='absolute top-2 right-2 text-white hover:text-red-500 hover:border-red-500 border-2 p-2 border-white rounded-full fill-current'
            >
              <DeleteIcon className='w-5 h-5' />
            </button>
            <div className='w-full h-full flex items-center justify-center'>
              <p className='text-white text-center'>
                Click to replace the image
              </p>
            </div>
          </div>
        </div>
      ) : (
        // Empty state
        <button
          className='w-full min-h-60 bg-main-layer border border-secondary rounded-xl flex flex-col items-center justify-center gap-4 hover:bg-light-gray transition-colors'
          onClick={openMediaSearchModal}
        >
          <ImageIcon className='w-12 h-12 text-white' />
          <div className='text-center'>
            <div className='text-white'>Add Media</div>
          </div>
        </button>
      )}
    </>
  );
}

function Left(props: TrainingSlideEditorProps<HiddenPictureBlock>) {
  const firstPicture = props.block.fields.pictures?.[0];
  return (
    <>
      <div className='relative w-full h-full min-h-0 flex flex-col'>
        <main className='w-full flex-1 min-h-0 px-1 flex flex-col justify-center backdrop-filter backdrop-blur-lg'>
          <div className='flex-none pt-4 italic text-white text-center break-words text-base sm:text-xl lg:text-2xl'>
            <EditableFieldText {...props} field='instructions' />
          </div>
          <div className='flex-1 flex items-center justify-center'>
            <div className='w-full'>
              <MediaField {...props} />
            </div>
          </div>
        </main>

        <footer className='w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5'>
          {firstPicture?.mode !==
            EnumsHiddenPictureMode.HiddenPictureModeOne && (
            <CommonButton variant='gray' className='pointer-events-none'>
              Reveal Answers
            </CommonButton>
          )}
        </footer>
      </div>
    </>
  );
}

function Right(props: TrainingSlideEditorProps<HiddenPictureBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const openHotSpotEditor = () => {
    triggerModal({
      kind: 'custom',
      containerClassName: 'bg-black',
      element: (p) => (
        <HotSpotEditor
          {...props}
          onSave={(val) => {
            onChange('pictures', [{ ...val, id: uuidv4() }]);
            onBlur('pictures', [{ ...val, id: uuidv4() }]);
            p.internalOnConfirm();
          }}
          onCancel={p.internalOnCancel}
        />
      ),
    });
  };

  return (
    <div className='w-full h-full flex flex-col gap-5 text-white'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={block.fields.personalityId}
          isClearable
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Block Intro</p>
        <BlockIntroSelect
          value={block.fields.intro}
          onChange={(value) => {
            onChange('intro', value);
            onBlur('intro', value);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Background Music</p>
        <BlockMusicSelect
          value={block.fields.bgMusic}
          onChange={(value) => {
            onChange('bgMusic', value);
            // Do not persist the decorated media object.
            const out = cloneDeep(value);
            delete out?.asset.media;
            onBlur('bgMusic', out);
          }}
        />
      </label>

      <button
        type='button'
        className='w-full h-10 btn-secondary'
        onClick={openHotSpotEditor}
      >
        Edit Hotspots
      </button>
    </div>
  );
}

function HotSpotEditorSidebar(
  props: TrainingSlideEditorProps<HiddenPictureBlock> & {
    selectedHotSpot?: SelectedHotSpot;
    setSelectedHotSpot: (selectedHotSpot: SelectedHotSpot | undefined) => void;
    hotSpots: UseFieldArrayReturn<HiddenPictureFormData, 'hotSpotsV2'>;
    mode: EnumsHiddenPictureMode | undefined;
    setMode: (mode: EnumsHiddenPictureMode) => void;
  }
) {
  const hotSpotInputsRef = useRef<HTMLDivElement>(null);
  const { selectedHotSpot, setSelectedHotSpot, mode, setMode } = props;
  const { fields, append, remove, update } = props.hotSpots;

  const handleAddMore = (props: {
    shape: HotSpotShape;
    shapeData: HotSpotShapeData;
  }) => {
    const id = uuidv4();
    const newHotSpot = {
      ...props,
      id,
      name: '',
      points: 10,
    };
    append(newHotSpot);
    setSelectedHotSpot({ id, ping: true, autoScroll: true });
  };

  useLayoutEffect(() => {
    if (
      !hotSpotInputsRef.current ||
      !selectedHotSpot ||
      !selectedHotSpot.autoScroll
    )
      return;

    // find the index of this hot spot
    const index = fields.findIndex((h) => h.id === selectedHotSpot.id);
    if (index === -1) return;

    // set the scroll position so it's in view
    hotSpotInputsRef.current.scrollTop =
      index * (HOT_SPOT_EDITOR_HEIGHT_PX + HOT_SPOT_EDITOR_GAP_PX);
  }, [fields, selectedHotSpot]);

  const handleSelectHotSpotFromSideBar = useCallback(
    (id: string) => {
      setSelectedHotSpot({ id, ping: true, autoScroll: false });
    },
    [setSelectedHotSpot]
  );

  const handleClickAddItem = useLiveCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.metaKey) {
        handleAddMore({
          shape: HotSpotShape.Rectangle,
          shapeData: {
            rectangle: {
              top: 0,
              left: 0,
              height: 0.05,
              width: 0.05 / ASPECT_RATIO,
            },
          },
        });
      } else {
        handleAddMore({
          shape: HotSpotShape.Circle,
          shapeData: {
            circle: {
              top: 0,
              left: 0,
              radius: 0.025,
            },
          },
        });
      }
    }
  );

  const hotSpotInputs = (
    <>
      {fields.map((hotSpot, index) => (
        <div className='w-full' key={hotSpot.id}>
          <HotSpotInput
            initialData={hotSpot}
            onDelete={() => {
              setSelectedHotSpot(undefined);
              remove(index);
            }}
            onSelect={() => handleSelectHotSpotFromSideBar(hotSpot.id)}
            onUpdate={(updated) => {
              update(index, updated);
            }}
            onDuplicate={() => {
              const id = uuidv4();
              const newHotSpot = {
                ...hotSpot,
                id,
                name: `Copy of ${hotSpot.name}`,
              };
              append(newHotSpot);
              setSelectedHotSpot({ id, ping: true, autoScroll: true });
            }}
            selected={selectedHotSpot?.id === hotSpot.id}
            index={index}
            sequenced={false}
          />
        </div>
      ))}
    </>
  );

  const handleShapeButtonClick = useLiveCallback(
    async (shape: HotSpotShape) => {
      for (let i = 0; i < fields.length; i++) {
        const hotSpot = fields[i];
        const shouldConvert =
          selectedHotSpot === undefined || hotSpot.id === selectedHotSpot.id;
        if (!shouldConvert) continue;

        update(i, HiddenPictureUtils.ConvertShape(hotSpot, shape));
      }
    }
  );

  return (
    <div className='w-full h-full flex flex-col gap-5 text-white bg-main-layer rounded-xl p-4'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Mode</p>
        <ModeSelect value={mode} onChange={setMode} />
      </label>
      <div className='col-span-full lg:col-span-1 relative'>
        <div className='flex justify-between mb-1'>
          <p className='text-base text-white font-bold'>Hot Spots</p>
          {selectedHotSpot && (
            <ShapeMenuButton onSelected={handleShapeButtonClick} />
          )}
        </div>
        <div
          ref={hotSpotInputsRef}
          className='flex flex-col overflow-y-scroll overflow-x-hidden scrollbar gap-1'
        >
          {hotSpotInputs}
        </div>
        <div className='flex items-baseline gap-4'>
          <button
            type='button'
            className='btn text-xs text-primary mt-2'
            onClick={handleClickAddItem}
          >
            + Add Hot Spot
          </button>
        </div>
      </div>
    </div>
  );
}

function defaultFormData(): HiddenPictureFormData {
  return {
    tool: 'none',
    sequenced: false,
    hotSpotsV2: [],
    question: '',
    everyoneClicks: false,
    name: '',
    incorrectAnswerPenalty: 0,
    mainMedia: null,
    mainMediaData: null,
    pinDropHidden: false,
    pinDropMuted: false,
    hidePointsAnimation: false,
    asymmetricGamePlay: false,
    asymmetricMedia: null,
    asymmetricMediaData: null,
    asymmetricPinDropVisibility:
      EnumsHiddenPictureAsymmetricPinDropVisibility.HiddenPictureAsymmetricPinDropVisibilityHidden,
    asymmetricPinDropAudibility:
      EnumsHiddenPictureAsymmetricPinDropAudibility.HiddenPictureAsymmetricPinDropAudibilityMuted,
  };
}

function HotSpotEditor(
  props: TrainingSlideEditorProps<HiddenPictureBlock> & {
    onSave: (val: HiddenPictureFormData) => void;
    onCancel: () => void;
  }
) {
  const [selectedHotSpot, setSelectedHotSpot] = useState<
    SelectedHotSpot | undefined
  >(undefined);

  const { onCancel, onSave } = props;

  const hiddenPicture = props.block.fields.pictures?.[0];

  const form = useForm<HiddenPictureFormData>({
    mode: 'onChange',
    defaultValues: hiddenPicture ?? defaultFormData(),
  });

  const mode = form.watch('mode');
  const setMode = (mode: EnumsHiddenPictureMode) => {
    form.setValue('mode', mode);
  };

  const hotSpots = useFieldArray<HiddenPictureFormData, 'hotSpotsV2', 'key'>({
    name: 'hotSpotsV2',
    keyName: 'key',
    control: form.control,
  });

  return (
    <div className='w-full h-full flex flex-col gap-4'>
      <header className='flex justify-end gap-4'>
        <button
          type='button'
          className='btn-secondary w-33 h-10'
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type='button'
          className='btn-primary w-33 h-10'
          onClick={form.handleSubmit(onSave)}
        >
          Save
        </button>
      </header>
      <FormProvider<HiddenPictureFormData> {...form}>
        <section className='w-full h-full flex gap-4 pb-2'>
          <aside className='w-72 h-full flex-none'>
            <HotSpotEditorSidebar
              {...props}
              selectedHotSpot={selectedHotSpot}
              setSelectedHotSpot={setSelectedHotSpot}
              hotSpots={hotSpots}
              mode={mode ?? undefined}
              setMode={setMode}
            />
          </aside>
          <main className='flex-1 flex flex-col gap-2'>
            <HotSpotImageEditor
              {...props}
              selectedHotSpot={selectedHotSpot}
              setSelectedHotSpot={setSelectedHotSpot}
              hotSpots={hotSpots}
            />
          </main>
        </section>
      </FormProvider>
    </div>
  );
}

type HandlePos = 'nw' | 'ne' | 'sw' | 'se';
export const MIN_BOX_RATIO = 0.05;

function HotSpotCircle(props: {
  hotSpot: HotSpotV2;
  index: number;
  containerRect?: { width: number; height: number };
  onSelect: () => void;
  onUpdate: (updated: HotSpotV2) => void;
  onDelete: () => void;
  selected?: boolean;
  ping?: boolean;
  sequenced?: boolean;
}): JSX.Element | null {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.hotSpot.id,
    data: {
      type: 'hotspot',
    },
    disabled: !props.selected,
  });

  const handleDelete = useLiveCallback(() => {
    if (!props.selected) return;
    props.onDelete();
  });

  useKey('Escape', handleDelete);
  useKey('Delete', handleDelete);

  const [resizeTransform, setResizeTransform] = useState<
    { top: number; left: number; boxSize: number } | undefined
  >(undefined);

  if (!props.containerRect) return null;

  const container = {
    maxX: props.containerRect.width,
    maxY: props.containerRect.height,
  };

  const minBoxSize = MIN_BOX_RATIO * container.maxY;

  const hotSpot = HiddenPictureUtils.FromUnitGridHotSpot(
    props.hotSpot,
    container
  );
  const shape = hotSpot.shapeData.circle;
  if (!shape) return null;

  const handleDragMove = (event: DragMoveEvent) => {
    const handlePos: HandlePos = event.active.data.current?.pos;
    const { initial, translated } = event.active.rect.current;
    if (!initial || !translated || !handlePos) return;

    // Calculate the new size based on the handle that's being dragged.
    const size = shape.radius * 2;
    const handleX = shape.left + event.delta.x;
    const handleY = shape.top + event.delta.y;
    const bound = (size: number) => Math.max(minBoxSize, size);

    let newSize: number;
    switch (handlePos) {
      case 'nw':
        newSize = bound(
          Math.max(shape.left + size - handleX, shape.top + size - handleY)
        );
        return setResizeTransform({
          top: shape.top + size - newSize,
          left: shape.left + size - newSize,
          boxSize: newSize,
        });
      case 'ne':
        newSize = bound(
          Math.max(handleX - shape.left, shape.top + size - handleY)
        );
        return setResizeTransform({
          top: shape.top + size - newSize,
          left: shape.left,
          boxSize: newSize,
        });
      case 'sw':
        newSize = bound(
          Math.max(shape.left + size - handleX, handleY - shape.top)
        );
        return setResizeTransform({
          top: shape.top,
          left: shape.left + size - newSize,
          boxSize: newSize,
        });
      case 'se':
        newSize = bound(
          Math.max(handleX + size - shape.left, handleY + size - shape.top)
        );
        return setResizeTransform({
          top: shape.top,
          left: shape.left,
          boxSize: newSize,
        });
      default:
        assertExhaustive(handlePos);
    }
  };

  const handleDragEnd = () => {
    if (!resizeTransform) return;

    let updated = HiddenPictureUtils.ToUnitGridHotSpot(
      {
        ...props.hotSpot,
        shapeData: {
          circle: {
            top: resizeTransform.top,
            left: resizeTransform.left,
            radius: resizeTransform.boxSize / 2,
          },
        },
      },
      container
    );
    updated = HiddenPictureUtils.Bound(updated);
    props.onUpdate(updated);
    setResizeTransform(undefined);
  };

  const fontSize =
    shape.radius < 0.1
      ? 'text-sm'
      : shape.radius < 0.2
      ? 'text-lg'
      : shape.radius < 0.3
      ? 'text-xl'
      : 'text-2xl';

  const boxSize = resizeTransform?.boxSize ?? shape.radius * 2;
  const x = transform?.x ?? 0;
  const y = transform?.y ?? 0;
  const color = props.hotSpot.points < 0 ? 'bg-[#CA2121]' : 'bg-green-400';

  return (
    <DndContext onDragMove={handleDragMove} onDragEnd={handleDragEnd}>
      <div
        ref={setNodeRef}
        className={`
          absolute
          box-content 
          border ${
            props.selected
              ? 'border-primary z-10 cursor-move'
              : 'border-transparent cursor-pointer'
          }
        `}
        style={{
          height: boxSize,
          width: 'auto',
          aspectRatio: '1 / 1',
          top: resizeTransform?.top ?? shape.top,
          left: resizeTransform?.left ?? shape.left,
          transform: `translate3d(${x}px, ${y}px, 0)`,
        }}
        onClick={(e) => {
          e.stopPropagation();
          props.onSelect();
        }}
        {...attributes}
        {...listeners}
      >
        <div
          className={`
            ${color} bg-opacity-50 rounded-full w-full h-full flex items-center justify-center select-none
          `}
        >
          {props.sequenced && (
            <div className={`${fontSize} font-mono font-bold`}>
              {props.index + 1}
            </div>
          )}
        </div>
        {props.ping && (
          <div
            className={`animate-ping-once absolute inset-0 h-full w-full rounded-full ${color}`}
          />
        )}

        {props.selected && (
          <>
            <Handle id={props.hotSpot.id} pos='nw' />
            <Handle id={props.hotSpot.id} pos='ne' />
            <Handle id={props.hotSpot.id} pos='sw' />
            <Handle id={props.hotSpot.id} pos='se' />
          </>
        )}
      </div>
    </DndContext>
  );
}

function HotSpotRectangle(props: {
  hotSpot: HotSpotV2;
  index: number;
  containerRect?: { width: number; height: number };
  onSelect: () => void;
  onUpdate: (updated: HotSpotV2) => void;
  onDelete: () => void;
  selected?: boolean;
  ping?: boolean;
  sequenced?: boolean;
}): JSX.Element | null {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.hotSpot.id,
    data: {
      type: 'hotspot',
    },
    disabled: !props.selected,
  });

  const handleDelete = useLiveCallback(() => {
    if (!props.selected) return;
    props.onDelete();
  });

  useKey('Backspace', handleDelete);

  const [resizeTransform, setResizeTransform] = useState<
    { top: number; left: number; width: number; height: number } | undefined
  >(undefined);

  if (!props.containerRect) return null;

  const container = {
    maxX: props.containerRect.width,
    maxY: props.containerRect.height,
  };

  const hotSpot = HiddenPictureUtils.FromUnitGridHotSpot(
    props.hotSpot,
    container
  );
  const shape = hotSpot.shapeData.rectangle;
  if (!shape) return null;

  const handleDragMove = (event: DragMoveEvent) => {
    const data = event.active.data.current;
    const type = data?.type;
    const { initial, translated } = event.active.rect.current;
    if (!initial || !translated || !type) return;

    if (type === 'handle') {
      const handlePos: HandlePos = data.pos;
      switch (handlePos) {
        case 'nw': {
          const nextWidth = shape.width - event.delta.x;
          const nextHeight = shape.height - event.delta.y;

          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? shape.height : event.delta.y),
            left: shape.left + (nextWidth < 0 ? shape.width : event.delta.x),
            width: Math.abs(nextWidth),
            height: Math.abs(nextHeight),
          });
        }
        case 'ne': {
          const nextWidth = shape.width + event.delta.x;
          const nextHeight = shape.height - event.delta.y;

          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? shape.height : event.delta.y),
            left: shape.left + (nextWidth < 0 ? nextWidth : 0),
            width: Math.abs(nextWidth),
            height: Math.abs(nextHeight),
          });
        }
        case 'sw': {
          const nextWidth = shape.width - event.delta.x;
          const nextHeight = shape.height + event.delta.y;

          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? nextHeight : 0),
            left: shape.left + (nextWidth < 0 ? shape.width : event.delta.x),
            width: Math.abs(nextWidth),
            height: Math.abs(nextHeight),
          });
        }
        case 'se': {
          const nextWidth = shape.width + event.delta.x;
          const nextHeight = shape.height + event.delta.y;

          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? nextHeight : 0),
            left: shape.left + (nextWidth < 0 ? nextWidth : 0),
            width: Math.abs(nextWidth),
            height: Math.abs(nextHeight),
          });
        }
        default:
          assertExhaustive(handlePos);
      }
    } else if (type === 'gutter') {
      const gutterPos: GutterPos = data.pos;
      switch (gutterPos) {
        case 'n': {
          const nextHeight = shape.height - event.delta.y;
          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? shape.height : event.delta.y),
            left: shape.left,
            width: shape.width,
            height: Math.abs(nextHeight),
          });
        }
        case 's': {
          const nextHeight = shape.height + event.delta.y;
          return setResizeTransform({
            top: shape.top + (nextHeight < 0 ? nextHeight : 0),
            left: shape.left,
            width: shape.width,
            height: Math.abs(nextHeight),
          });
        }
        case 'w': {
          const nextWidth = shape.width - event.delta.x;
          return setResizeTransform({
            top: shape.top,
            left: shape.left + (nextWidth < 0 ? shape.width : event.delta.x),
            width: Math.abs(nextWidth),
            height: shape.height,
          });
        }
        case 'e': {
          const nextWidth = shape.width + event.delta.x;
          return setResizeTransform({
            top: shape.top,
            left: shape.left + (nextWidth < 0 ? nextWidth : 0),
            width: Math.abs(nextWidth),
            height: shape.height,
          });
        }
      }
    }
  };

  const handleDragEnd = () => {
    if (!resizeTransform) return;

    let updated = HiddenPictureUtils.ToUnitGridHotSpot(
      {
        ...props.hotSpot,
        shapeData: {
          rectangle: {
            top: resizeTransform.top,
            left: resizeTransform.left,
            width: resizeTransform.width,
            height: resizeTransform.height,
          },
        },
      },
      container
    );
    updated = HiddenPictureUtils.Bound(updated);
    props.onUpdate(updated);
    setResizeTransform(undefined);
  };

  const fontSize = 'text-base';
  const x = transform?.x ?? 0;
  const y = transform?.y ?? 0;
  const color = props.hotSpot.points < 0 ? 'bg-[#CA2121]' : 'bg-green-400';

  return (
    <DndContext onDragMove={handleDragMove} onDragEnd={handleDragEnd}>
      <div
        ref={setNodeRef}
        className={`
          absolute
          box-content 
          ${props.selected ? 'z-10 cursor-move' : 'cursor-pointer'}
        `}
        style={{
          height: resizeTransform?.height ?? shape.height,
          width: resizeTransform?.width ?? shape.width,
          top: resizeTransform?.top ?? shape.top,
          left: resizeTransform?.left ?? shape.left,
          transform: `translate3d(${x}px, ${y}px, 0)`,
        }}
        onClick={(e) => {
          e.stopPropagation();
          props.onSelect();
        }}
        {...attributes}
        {...listeners}
      >
        <div
          className={`
            ${color} bg-opacity-50 w-full h-full flex items-center justify-center select-none
          `}
        >
          {props.sequenced && (
            <div className={`${fontSize} font-mono font-bold`}>
              {props.index + 1}
            </div>
          )}
        </div>
        {props.ping && (
          <div
            className={`animate-ping-once absolute inset-0 h-full w-full ${color}`}
          />
        )}

        {props.selected && (
          <>
            <Handle id={props.hotSpot.id} pos='nw' />
            <Handle id={props.hotSpot.id} pos='ne' />
            <Handle id={props.hotSpot.id} pos='sw' />
            <Handle id={props.hotSpot.id} pos='se' />

            <Gutter id={props.hotSpot.id} pos='n' />
            <Gutter id={props.hotSpot.id} pos='s' />
            <Gutter id={props.hotSpot.id} pos='w' />
            <Gutter id={props.hotSpot.id} pos='e' />
          </>
        )}
      </div>
    </DndContext>
  );
}

function Gutter(props: { id: string; pos: GutterPos }) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `${props.id}-${props.pos}`,
    data: {
      type: 'gutter',
      pos: props.pos,
    },
  });

  const pos =
    props.pos === 'n'
      ? 'top-0 left-0 right-0 h-1 border-t border-primary'
      : props.pos === 's'
      ? 'bottom-0 left-0 right-0 h-1 border-b border-primary'
      : props.pos === 'w'
      ? 'left-0 top-0 bottom-0 w-1 border-l border-primary'
      : 'right-0 top-0 bottom-0 w-1 border-r border-primary';

  const cursor =
    props.pos === 'n' || props.pos === 's'
      ? 'cursor-[ns-resize]'
      : 'cursor-[ew-resize]';

  return (
    <div
      ref={setNodeRef}
      className={`absolute z-5 ${cursor} ${pos}`}
      {...attributes}
      {...listeners}
    />
  );
}

function Handle(props: { id: string; pos: HandlePos }) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `${props.id}-${props.pos}`,
    data: {
      type: 'handle',
      pos: props.pos,
    },
  });

  const pos =
    props.pos === 'nw'
      ? '-top-0.5 -left-0.5'
      : props.pos === 'ne'
      ? '-top-0.5 -right-0.5'
      : props.pos === 'sw'
      ? '-bottom-0.5 -left-0.5'
      : '-bottom-0.5 -right-0.5';

  const cursor =
    props.pos === 'nw' || props.pos === 'se'
      ? 'cursor-[nwse-resize]'
      : 'cursor-[nesw-resize]';

  return (
    <div
      ref={setNodeRef}
      className={`absolute bg-white w-1 h-1 z-5 ${cursor} ${pos}`}
      {...attributes}
      {...listeners}
    />
  );
}

function HotSpotImageEditor(
  props: TrainingSlideEditorProps<HiddenPictureBlock> & {
    selectedHotSpot?: SelectedHotSpot;
    setSelectedHotSpot: (selectedHotSpot: SelectedHotSpot | undefined) => void;
    hotSpots: UseFieldArrayReturn<HiddenPictureFormData, 'hotSpotsV2'>;
  }
): JSX.Element {
  const [containerRef, containerRect] = useMeasure({
    polyfill: ResizeObserver,
  });

  const { selectedHotSpot, setSelectedHotSpot } = props;

  const { fields, append, remove, update } = props.hotSpots;

  const sequenced = useWatch<HiddenPictureFormData, 'sequenced'>({
    name: 'sequenced',
  });

  const mainMedia = useWatch<HiddenPictureFormData, 'mainMedia'>({
    name: 'mainMedia',
  });

  const handleAddMore = (props: {
    shape: HotSpotShape;
    shapeData: HotSpotShapeData;
  }) => {
    const id = uuidv4();
    const newHotSpot = {
      ...props,
      id,
      name: '',
      points: 10,
    };
    append(newHotSpot);
    setSelectedHotSpot({ id, ping: true, autoScroll: true });
  };

  const handleHotSpotDragged = useLiveCallback((event: DragEndEvent) => {
    if (!containerRect || (event.delta.x === 0 && event.delta.y === 0)) return;

    const index = fields.findIndex((c) => c.id === event.active.id);
    if (index === -1) return;

    // convert the delta to a unit delta
    const unitDelta = HiddenPictureUtils.ToUnitGrid(event.delta, {
      maxX: containerRect.width,
      maxY: containerRect.height,
    });
    const hotSpot = fields[index];
    switch (hotSpot.shape) {
      case HotSpotShape.Circle: {
        const shapeData = hotSpot.shapeData.circle;
        if (!shapeData) return;

        const updated = HiddenPictureUtils.Bound({
          ...hotSpot,
          shapeData: {
            circle: {
              ...shapeData,
              top: shapeData.top + unitDelta.y,
              left: shapeData.left + unitDelta.x,
            },
          },
        });
        update(index, updated);
        break;
      }

      case HotSpotShape.Rectangle: {
        const shapeData = hotSpot.shapeData.rectangle;
        if (!shapeData) return;

        const updated = HiddenPictureUtils.Bound({
          ...hotSpot,
          shapeData: {
            rectangle: {
              ...shapeData,
              top: shapeData.top + unitDelta.y,
              left: shapeData.left + unitDelta.x,
            },
          },
        });
        update(index, updated);
        break;
      }

      default:
        break;
    }
  });

  const handleSelectHotSpotFromImage = useCallback(
    (id: string) => {
      setSelectedHotSpot({ id, ping: false, autoScroll: true });
    },
    [setSelectedHotSpot]
  );

  const hotSpots = fields.map((hotSpot, index) => {
    switch (hotSpot.shape) {
      case HotSpotShape.Circle:
        return (
          <HotSpotCircle
            key={hotSpot.id}
            hotSpot={hotSpot}
            index={index}
            containerRect={containerRect}
            onSelect={() => handleSelectHotSpotFromImage(hotSpot.id)}
            onUpdate={(updated) => update(index, updated)}
            onDelete={() => remove(index)}
            selected={selectedHotSpot?.id === hotSpot.id}
            ping={selectedHotSpot?.ping && selectedHotSpot.id === hotSpot.id}
            sequenced={sequenced}
          />
        );
      case HotSpotShape.Rectangle:
        return (
          <HotSpotRectangle
            key={hotSpot.id}
            hotSpot={hotSpot}
            index={index}
            containerRect={containerRect}
            onSelect={() => handleSelectHotSpotFromImage(hotSpot.id)}
            onUpdate={(updated) => update(index, updated)}
            onDelete={() => remove(index)}
            selected={selectedHotSpot?.id === hotSpot.id}
            ping={selectedHotSpot?.ping && selectedHotSpot.id === hotSpot.id}
            sequenced={sequenced}
          />
        );
      default:
        return null;
    }
  });

  const onClick = useLiveCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (mainMedia && e.detail === 2) {
      const bounds = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - bounds.left;
      const y = e.clientY - bounds.top;

      const unitDelta = HiddenPictureUtils.ToUnitGrid(
        { x, y },
        {
          maxX: containerRect.width,
          maxY: containerRect.height,
        }
      );

      if (e.metaKey) {
        const height = 0.05;
        const width = height / ASPECT_RATIO;
        handleAddMore({
          shape: HotSpotShape.Rectangle,
          shapeData: {
            rectangle: {
              top: unitDelta.y - height / 2,
              left: unitDelta.x - width / 2,
              height,
              width,
            },
          },
        });
      } else {
        const radius = 0.025;
        handleAddMore({
          shape: HotSpotShape.Circle,
          shapeData: {
            circle: {
              top: unitDelta.y - radius,
              left: unitDelta.x - radius / ASPECT_RATIO,
              radius,
            },
          },
        });
      }
    } else {
      setSelectedHotSpot(undefined);
    }
  });

  return (
    <div className='col-span-full lg:col-span-3 h-full'>
      <MainImageHeader />
      <DndContext onDragEnd={handleHotSpotDragged}>
        <div
          ref={containerRef}
          className='relative aspect-w-16 aspect-h-9 isolate overflow-hidden border border-dashed border-secondary rounded-xl'
          onClick={onClick}
        >
          <img src={mainMedia?.url} alt='' className='w-full h-full' />
          {mainMedia && hotSpots}
        </div>
      </DndContext>
    </div>
  );
}

function MainImageHeader(): JSX.Element {
  return (
    <div className='flex items-center mb-1 gap-3'>
      <div className='text-secondary'>
        Add hotspots to the image and assign points the player earns when
        clicking them.
        <br />
        The block will advance when all hotspots have been clicked.
      </div>
    </div>
  );
}

function ShapeMenuButton(props: { onSelected: (shape: HotSpotShape) => void }) {
  const [controlledVisibility, setControlledVisibility] = useState(false);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'click',
      interactive: true,
      visible: controlledVisibility,
      onVisibleChange: setControlledVisibility,
    });

  return (
    <div className='ml-auto relative flex items-center'>
      <button
        className='btn-secondary w-7 h-7 rounded-md flex items-center justify-center'
        ref={setTriggerRef}
        type='button'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-4 h-4 fill-current'
          viewBox='0 0 256 256'
        >
          <rect width='256' height='256' fill='none' />
          <polygon
            points='64 64 24 184 104 184 64 64'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='16'
          />
          <circle
            cx='156'
            cy='76'
            r='44'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='16'
          />
          <rect
            x='136'
            y='152'
            width='88'
            height='56'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='16'
          />
        </svg>
      </button>
      {visible && (
        <Menu ref={setTooltipRef} {...getTooltipProps()}>
          <MenuItem
            text='Circle'
            onClick={() => {
              props.onSelected(HotSpotShape.Circle);
              setControlledVisibility(false);
            }}
          />
          <MenuItem
            text='Rectangle'
            onClick={() => {
              props.onSelected(HotSpotShape.Rectangle);
              setControlledVisibility(false);
            }}
          />
        </Menu>
      )}
    </div>
  );
}

function HotSpotInput(props: {
  initialData: HotSpotV2;
  onDelete: () => void;
  onSelect: () => void;
  onUpdate: (updated: HotSpotV2) => void;
  onDuplicate: () => void;
  index: number;
  drag?: ConnectDragSource;
  selected?: boolean;
  sequenced?: boolean;
}): JSX.Element {
  const { initialData, onDelete, selected } = props;
  return (
    <div
      className='relative w-full min-w-60 group flex items-start gap-1 cursor-pointer rounded-xl'
      onMouseDown={() => props.onSelect()}
    >
      <div
        className={`
          w-full h-full p-1 
          flex items-center gap-1 
          rounded-md 
          bg-secondary group-hover:bg-secondary-hover
          border ${selected ? 'border-primary' : 'border-transparent'}
        `}
      >
        <div className='h-full'>
          <PointsInput
            defaultValue={props.initialData.points}
            onChange={(points) => {
              props.onUpdate({ ...initialData, points });
            }}
            renderValue={(value) => `${value} pts`}
          />
        </div>
        <div className='w-full h-full'>
          <input
            key={initialData.id}
            type='text'
            className='w-full h-10 mb-0 bg-secondary border-none outline-none pl-2 rounded-xl text-sms'
            placeholder={`Item ${props.index + 1}`}
            defaultValue={props.initialData.name}
            onBlur={(e) => {
              if (e.target.value === props.initialData.name) return;
              props.onUpdate({ ...initialData, name: e.target.value });
            }}
          />
        </div>
        <div className='flex flex-col gap-4'>
          <button
            type='button'
            className={`
              flex-none
              invisible group-hover:visible
              flex items-center justify-center
              text-red-002 transform hover:scale-110
            `}
            onClick={onDelete}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    </div>
  );
}
