import { useRef, useState } from 'react';

import { BlockType } from '@lp-lib/game';

import { useOutsideClick } from '../../../hooks/useOutsideClick';
import {
  MemoryMatchBlockIcon,
  MultipleChoiceBlockIcon,
  QuestionBlockIcon,
} from '../../icons/Block';
import { HiddenPictureBlockIcon } from '../../icons/Block/HiddenPictureBlockIcon';
import { RoleplayBlockIcon } from '../../icons/Block/RoleplayBlockIcon';
import { SlideBlockIcon } from '../../icons/Block/SlideBlockIcon';
import { SlideGroupIcon } from '../../icons/Slide/SlideGroupIcon';
import { TBDIcon } from '../../icons/TBDIcon';
import { ToolsIcon } from '../../icons/ToolsIcon';

function SlideChoice(props: {
  icon: (props: { className: string }) => JSX.Element;
  title: string;
  description?: string;
  onClick: () => void;
  disabled?: boolean;
}) {
  const { icon, title, description, onClick, disabled } = props;

  return (
    <button
      type='button'
      onClick={onClick}
      className='flex items-center gap-2.5 disabled:opacity-60 hover:bg-lp-black-003 p-2 rounded-lg'
      disabled={disabled}
    >
      {icon({ className: 'w-15 h-10 fill-current' })}
      <div className='flex flex-col gap-0.5'>
        <div className='text-sm font-bold'>{title}</div>
        {description && (
          <div className='text-3xs text-secondary'>{description}</div>
        )}
      </div>
    </button>
  );
}

function SlideTypeSection(props: {
  title: React.ReactNode;
  items: React.ReactNode[];
}) {
  const { title, items } = props;

  return (
    <div className='flex flex-col gap-2.5'>
      <div className='text-sm font-bold'>{title}</div>
      <div className='grid grid-cols-2 gap-2.5'>{items}</div>
    </div>
  );
}

function SlideTypeChooser(props: {
  onAddSlideGroup: () => void;
  blockAddDisabled?: boolean;
  onAddBlock: (blockType: BlockType) => void;
  onCancel: () => void;
}) {
  const { onAddSlideGroup, blockAddDisabled, onAddBlock, onCancel } = props;
  const ref = useRef<null | HTMLDivElement>(null);

  useOutsideClick(ref, () => onCancel());

  return (
    <div
      ref={ref}
      className='w-174 bg-main-layer border border-secondary rounded-xl text-white p-5 flex flex-col gap-5'
    >
      <SlideTypeSection
        title={
          <div className='text-sms font-bold flex items-center gap-2'>
            <ToolsIcon className='w-4 h-4 fill-current' />
            Structure
          </div>
        }
        items={[
          <SlideChoice
            key={'slide-group'}
            icon={SlideGroupIcon}
            title='Slide Group'
            description=''
            onClick={onAddSlideGroup}
          />,
        ]}
      />
      <SlideTypeSection
        title={
          <div className='text-sms font-bold flex items-center gap-2'>
            <ToolsIcon className='w-4 h-4 fill-current' />
            Slides
          </div>
        }
        items={[
          <SlideChoice
            key={'slide-block'}
            icon={() => (
              <div className='w-15 h-10 flex items-center justify-center'>
                <div className='w-8 h-8 flex items-center justify-center bg-blue-005 rounded-md'>
                  <SlideBlockIcon className='w-2/3 h-2/3 fill-current' />
                </div>
              </div>
            )}
            title='Slide'
            description=''
            onClick={() => onAddBlock(BlockType.SLIDE)}
            disabled={blockAddDisabled}
          />,
          <SlideChoice
            key={'multiple-choice-block'}
            icon={() => (
              <div className='w-15 h-10 flex items-center justify-center'>
                <MultipleChoiceBlockIcon className='w-12 h-8 fill-current' />
              </div>
            )}
            title='Multiple Choice Slide'
            description=''
            onClick={() => onAddBlock(BlockType.MULTIPLE_CHOICE)}
            disabled={blockAddDisabled}
          />,
          <SlideChoice
            key={'question-block'}
            icon={() => (
              <div className='w-15 h-10 flex items-center justify-center'>
                <QuestionBlockIcon className='w-12 h-8 fill-current' />
              </div>
            )}
            title='Question Slide'
            description=''
            onClick={() => onAddBlock(BlockType.QUESTION)}
            disabled={blockAddDisabled}
          />,
          <SlideChoice
            key={'memory-match-block'}
            icon={() => (
              <div className='w-15 h-10 flex items-center justify-center'>
                <MemoryMatchBlockIcon className='w-12 h-8 fill-current' />
              </div>
            )}
            title='Memory Match Slide'
            description=''
            onClick={() => onAddBlock(BlockType.MEMORY_MATCH)}
            disabled={blockAddDisabled}
          />,
          <SlideChoice
            key={'role-play-block'}
            icon={() => (
              <div className='w-15 h-10 flex items-center justify-center'>
                <div className='w-8 h-8 flex items-center justify-center bg-[#E96B24] rounded-md'>
                  <RoleplayBlockIcon className='w-2/3 h-2/3 fill-current' />
                </div>
              </div>
            )}
            title='Role Play Slide'
            description=''
            onClick={() => onAddBlock(BlockType.ROLEPLAY)}
            disabled={blockAddDisabled}
          />,
          <SlideChoice
            key={'draw-to-win-block'}
            icon={() => (
              <div className='w-15 h-10 flex items-center justify-center'>
                <TBDIcon className='w-12 h-8 fill-current' />
              </div>
            )}
            title='Draw to Win Slide'
            description=''
            onClick={() => onAddBlock(BlockType.DRAW_TO_WIN)}
            disabled={blockAddDisabled}
          />,
          <SlideChoice
            key={'hidden-picture-block'}
            icon={() => (
              <div className='w-15 h-10 flex items-center justify-center'>
                <HiddenPictureBlockIcon className='w-12 h-8 fill-current' />
              </div>
            )}
            title='Hidden Picture Slide'
            description=''
            onClick={() => onAddBlock(BlockType.HIDDEN_PICTURE)}
            disabled={blockAddDisabled}
          />,
        ]}
      />
    </div>
  );
}

export function NewSlideButton(props: {
  onAddSlideGroup: () => void;
  blockAddDisabled?: boolean;
  onAddBlock: (blockType: BlockType) => void;
}): JSX.Element {
  const { onAddSlideGroup, blockAddDisabled, onAddBlock } = props;

  const [open, setOpen] = useState(false);

  return (
    <div className='relative w-full'>
      <button
        type='button'
        onClick={() => setOpen(!open)}
        className='w-full h-10 btn-secondary text-sms'
      >
        + New Slide
      </button>

      {open && (
        <div className={`z-5 absolute left-full ml-7 -top-2`}>
          <SlideTypeChooser
            onAddSlideGroup={() => {
              onAddSlideGroup();
              setOpen(false);
            }}
            blockAddDisabled={blockAddDisabled}
            onAddBlock={(blockType) => {
              onAddBlock(blockType);
              setOpen(false);
            }}
            onCancel={() => setOpen(false)}
          />
        </div>
      )}
    </div>
  );
}
