import { type MediaProvider } from '../MediaProvider';
import { GiphyProvider } from './GiphyProvider';
import { SerpProvider } from './SerpApiProvider';
import { UnsplashProvider } from './UnsplashProvider';

export const mediaProviders: MediaProvider[] = [
  GiphyProvider,
  UnsplashProvider,
  SerpProvider,
];
