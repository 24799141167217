import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';

import {
  type CommonMedia,
  EnumsMediaScene,
  type ModelsDrawToWinMatchTool,
  type ModelsMediaAsset,
} from '@lp-lib/api-service-client/public';
import { type DrawToWinBlock } from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import { fromMediaDTO, toMediaDTO } from '../../../utils/api-dto';
import { MediaUtils } from '../../../utils/media';
import { useSnapshot } from '../../../utils/valtio';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { DrawingStageManager } from '../../GameV2/blocks/DrawToWin/DrawingStageManager';
import {
  BlurBackground,
  BrushIcon,
  BrushSizePicker,
  EraserIcon,
  MarkupToolButton,
} from '../../GameV2/blocks/DrawToWin/Shared';
import { CommonButton } from '../../GameV2/design/Button';
import { FFriendlyEditableText } from '../../GameV2/design/Editable';
import { UndoIcon2 } from '../../icons/UndoIcon';
import { MediaUploader } from '../../MediaUploader/MediaUploader';
import { MiniMediaUploader } from '../../MediaUploader/MiniMediaUploader';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { BlockMusicSelect } from './BlockMusicSelect';
import { useBlockFieldController, useTrainingSlideEditor } from './hooks';
import { BlockIntroSelect } from './Shared/BlockIntroSelect';
import { type TrainingSlideEditorProps } from './types';

export function DrawToWinBlockEditor(
  props: TrainingSlideEditorProps<DrawToWinBlock>
) {
  return <Left {...props} />;
}

export function DrawToWinBlockSidebarEditor(
  props: TrainingSlideEditorProps<DrawToWinBlock>
) {
  return <Right {...props} />;
}

function MediaField(props: TrainingSlideEditorProps<DrawToWinBlock>) {
  const { value, updateLocal, updateRemote } = useBlockFieldController(
    props.block,
    'media'
  );

  return (
    <MediaUploader
      media={fromMediaDTO(value?.media)}
      scene={EnumsMediaScene.MediaSceneBlockMedia}
      video={false}
      width='w-full'
      onUploadSuccess={(media) => {
        const asset: ModelsMediaAsset = {
          media: toMediaDTO(media),
          data: {
            id: media.id,
          },
        };
        updateLocal(asset);
        updateRemote(asset);
      }}
      onMediaDelete={() => {
        updateLocal(null);
        updateRemote(null);
      }}
      objectFit='object-cover'
    />
  );
}

function Left(props: TrainingSlideEditorProps<DrawToWinBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <>
      <BlurBackground backgroundMedia={block.fields.media?.media} />
      <div className='relative w-full h-full min-h-0 flex flex-col'>
        <main className='w-full flex-1 min-h-0 px-1 flex flex-col justify-center gap-10 lg:gap-5 backdrop-filter backdrop-blur-lg'>
          <div className='text-white text-base text-center'>
            <FFriendlyEditableText
              value={props.block.fields.question || ''}
              onBlur={(val) => {
                onChange('question', val);
                onBlur('question', val);
              }}
              className={`
              w-full outline-none cursor-text
              contenteditable-placeholder whitespace-pre-wrap break-words
              text-base sm:text-xl lg:text-2xl
            `}
              placeholder='Your Question Here'
              style={{ '--placeholder-color': '#8B9294' }}
            />
          </div>
          <label className='text-white font-bold italic text-center text-base sm:text-xl lg:text-2xl'>
            Tap and drag to draw
          </label>
          <div className='w-full'>
            <MediaField {...props} />
          </div>
        </main>

        <footer className='w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5'>
          <CommonButton variant='brand' className='pointer-events-none'>
            Finish Job
          </CommonButton>
        </footer>
      </div>
    </>
  );
}

function Right(props: TrainingSlideEditorProps<DrawToWinBlock>) {
  const { block } = props;
  const backgroundMedia = block.fields.media?.media;
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const toolIconUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(block.fields.matchTool?.icon?.media)
  );

  const openMatchToolEditor = () => {
    triggerModal({
      kind: 'custom',
      containerClassName: 'bg-black',
      element: (p) => (
        <MatchToolEditor
          backgroundMedia={backgroundMedia}
          defaultValue={block.fields.matchTool}
          onCancel={p.internalOnCancel}
          onSave={(val) => {
            onChange('matchTool', val);
            onBlur('matchTool', val);
            p.internalOnCancel();
          }}
        />
      ),
    });
  };

  return (
    <div className='w-full h-full flex flex-col gap-5 text-white'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={block.fields.personalityId}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Block Intro</p>
        <BlockIntroSelect
          value={block.fields.intro}
          onChange={(value) => {
            onChange('intro', value);
            onBlur('intro', value);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Background Music</p>
        <BlockMusicSelect
          value={block.fields.bgMusic}
          onChange={(value) => {
            onChange('bgMusic', value);
            // Do not persist the decorated media object.
            const out = cloneDeep(value);
            delete out?.asset.media;
            onBlur('bgMusic', out);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Tools</p>
        <div className='w-full flex p-2 bg-lp-gray-003 rounded-lg gap-1'>
          <div
            className='w-16 border border-secondary rounded-xl 
            text-xs text-secondary flex items-center justify-center'
            style={{
              aspectRatio: 4 / 3,
              backgroundColor: '#D9D9D9',
              backgroundImage: toolIconUrl ? `url(${toolIconUrl})` : undefined,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          >
            {toolIconUrl ? null : 'Icon'}
          </div>
          <div className='text-xs font-bold mt-2'>
            {block.fields.matchTool?.name || 'Unnamed'}
          </div>
          <button
            type='button'
            className='btn text-primary underline text-sms ml-auto'
            onClick={openMatchToolEditor}
          >
            Edit
          </button>
        </div>
      </label>
    </div>
  );
}

function MatchToolSidebar() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ModelsDrawToWinMatchTool>();
  return (
    <div className='w-full h-full flex flex-col gap-5 text-white bg-main-layer rounded-xl p-4'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Tool Name</p>
        <input className='field h-10 mb-0' {...register('name')} />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Tool Icon</p>
        <div style={{ width: 'fit-content' }}>
          <Controller
            control={control}
            name={'icon'}
            render={({ field }) => (
              <MiniMediaUploader
                uploaderId='match-tool-icon-uploader'
                onUploadSuccess={(media) => {
                  field.onChange({
                    media: toMediaDTO(media),
                    data: {
                      id: media.id,
                    },
                  });
                }}
                onDelete={() => field.onChange(null)}
                media={fromMediaDTO(field.value?.media)}
              />
            )}
          />
        </div>
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>
          Correct Passing Grade
        </p>
        <input
          type='number'
          step={0.01}
          min={0}
          max={1}
          className={`${
            errors.correctThreshold ? 'field-error' : 'field'
          } mb-0 h-10`}
          {...register('correctThreshold', {
            valueAsNumber: true,
            required: true,
          })}
        />
        <p className='text-sms text-icon-gray mt-1'>
          Percentage of lines drawn within the correct area.
        </p>
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>
          Missing Passing Grade
        </p>
        <input
          type='number'
          step={0.01}
          min={0}
          max={1}
          className={`${
            errors.missedThreshold ? 'field-error' : 'field'
          } mb-0 h-10`}
          {...register('missedThreshold', {
            valueAsNumber: true,
            required: true,
          })}
        />
        <p className='text-sms text-icon-gray mt-1'>
          Percentage of area missed.
        </p>
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>
          Allowed to be wrong
        </p>
        <input
          type='number'
          step={0.01}
          min={0}
          max={1}
          className={`${
            errors.wrongThreshold ? 'field-error' : 'field'
          } mb-0 h-10`}
          {...register('wrongThreshold', {
            valueAsNumber: true,
            required: true,
          })}
        />
        <p className='text-sms text-icon-gray mt-1'>
          Percentage of area allowed to be wrong.
        </p>
      </label>
    </div>
  );
}

function MatchToolMarkupToolbar(props: { stageman: DrawingStageManager }) {
  const { stageman } = props;
  const state = useSnapshot(stageman.state);
  return (
    <div className='w-full flex items-center justify-between'>
      <div className='flex items-center gap-3'>
        <MarkupToolButton
          label='Circle'
          accessory={
            <div className='bg-lp-blue-002 w-6 h-6 rounded-full'></div>
          }
          className='border border-secondary'
          onClick={() => stageman?.addCircle()}
        />
        <MarkupToolButton
          label='Square'
          accessory={<div className='bg-lp-blue-002 w-5 h-5'></div>}
          className='border border-secondary'
          onClick={() => stageman?.addRect()}
        />
        <MarkupToolButton
          label='Eraser'
          accessory={<EraserIcon />}
          onClick={() => stageman.toggleBrushMode('eraser')}
          className={`border ${
            state.brushMode === 'eraser' ? 'border-white' : 'border-secondary'
          }`}
          active={state.brushMode === 'eraser'}
        />
        <MarkupToolButton
          label='Brush'
          accessory={<BrushIcon />}
          className={`border ${
            state.brushMode === 'brush' ? 'border-white' : 'border-secondary'
          }`}
          onClick={() =>
            stageman.toggleBrushMode(
              state.brushMode === 'brush' ? false : 'brush'
            )
          }
          active={state.brushMode === 'brush'}
        />
        <BrushSizePicker
          stageman={stageman}
          className={`border ${
            !!state.brushMode ? 'border-white' : 'border-secondary'
          }`}
        />
      </div>
      <div className='flex items-center gap-3 self-end'>
        <button
          type='button'
          className='btn text-white w-15 h-15 bg-transparent
        lg:hover:bg-light-gray active:bg-light-gray flex items-center
        justify-center rounded-lg'
          onClick={() => stageman.undo()}
          disabled={state.historyLength === 0}
        >
          <UndoIcon2 className='w-8 h-8 fill-current' />
        </button>
      </div>
    </div>
  );
}

function MatchToolEditor(props: {
  backgroundMedia: CommonMedia | null | undefined;
  defaultValue: ModelsDrawToWinMatchTool | null;
  onCancel: () => void;
  onSave: (val: ModelsDrawToWinMatchTool) => void;
}) {
  const { backgroundMedia } = props;
  const [stageman, setStageman] = useState<DrawingStageManager | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const anchor = useRef<HTMLDivElement>(null);
  const form = useForm<ModelsDrawToWinMatchTool>({
    defaultValues: {
      ...props.defaultValue,
      icon: props.defaultValue?.icon ?? null,
      correctThreshold: props.defaultValue?.correctThreshold ?? 0.8,
      missedThreshold: props.defaultValue?.missedThreshold ?? 0.15,
      wrongThreshold: props.defaultValue?.wrongThreshold ?? 0.05,
    },
  });

  const onSubmit = form.handleSubmit((val) => {
    if (!stageman) return;
    const drawing = stageman.export();
    val.drawing = drawing;
    props.onSave(val);
  });

  useEffect(() => {
    if (!ref.current || !anchor.current) return;
    const format = MediaUtils.PickMediaFormat(fromMediaDTO(backgroundMedia), {
      priority: [MediaFormatVersion.Raw],
    });
    if (!format) return;
    const container = ref.current;
    const aborter = new AbortController();
    const stageman = new DrawingStageManager(
      container,
      anchor.current,
      backgroundMedia,
      'edit',
      aborter.signal
    );
    if (props.defaultValue?.drawing) {
      stageman.import(props.defaultValue.drawing, 'draw');
    }
    setStageman(stageman);
    return () => {
      aborter.abort();
      stageman.destroy();
    };
  }, [backgroundMedia, props.defaultValue?.drawing]);

  return (
    <FormProvider {...form}>
      <div className='w-full h-full flex flex-col gap-4'>
        <header className='flex justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-33 h-10'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-33 h-10'
            onClick={onSubmit}
          >
            Save
          </button>
        </header>
        <section className='w-full h-full flex gap-4 pb-2'>
          <aside className='w-72 h-full flex-none'>
            <MatchToolSidebar />
          </aside>
          <main className='flex-1 flex flex-col gap-2'>
            {stageman && <MatchToolMarkupToolbar stageman={stageman} />}
            <div className='w-full relative flex-1 bg-secondary rounded-xl'>
              <div
                ref={anchor}
                className='w-full h-full absolute inset-0 pointer-events-none'
              />
              <div
                ref={ref}
                className='rounded-lg w-full flex-1 outline-none overflow-hidden absolute'
                tabIndex={0}
              />
            </div>
          </main>
        </section>
      </div>
    </FormProvider>
  );
}
