import { apiService } from '../../../services/api-service';
import { SearchIcon } from '../../icons/SearchIcon';
import { type MediaItem, type MediaProvider } from '../MediaProvider';

export const SerpProvider: MediaProvider = {
  name: 'Google Images',
  icon: <SearchIcon className='w-8 h-8 p-2 rounded-lg bg-white' />,
  async searchMedia(query: string, offset = 0): Promise<MediaItem[]> {
    const response = await apiService.media.searchExternalMedia({
      provider: 'serp',
      q: query,
      offset,
      size: 25,
    });

    return response.data.items;
  },
};
