import { apiService } from '../../../services/api-service';
import { getStaticAssetPath } from '../../../utils/assets';
import { type MediaItem, type MediaProvider } from '../MediaProvider';

const UNSPLASH_LOGO = getStaticAssetPath('images/unsplash-logo.png');

export const UnsplashProvider: MediaProvider = {
  name: 'Unsplash',
  icon: <img src={UNSPLASH_LOGO} alt='Unsplash logo' className='w-8 h-8' />,
  async searchMedia(query: string, offset = 0): Promise<MediaItem[]> {
    const response = await apiService.media.searchExternalMedia({
      provider: 'unsplash',
      q: query,
      offset,
      size: 25,
    });

    return response.data.items;
  },
};
