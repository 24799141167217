import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';

export function EditableTextWithQuotes(props: {
  value: string;
  onBlur: (value: string) => void;
}) {
  const { value, onBlur } = props;

  const [html, setHtml] = useState(`“${value}”`);

  useEffect(() => {
    setHtml(`“${value}”`);
  }, [value]);

  const handleChange = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const content = e.currentTarget.innerHTML;
    setHtml(content);
  };

  const handleBlur = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const content = e.currentTarget.innerText;
    const contentWithoutQuotes = content.replace(/^["“”]|["“”]$/g, '').trim();
    const wrappedContent = `“${contentWithoutQuotes}”`;
    setHtml(wrappedContent);
    onBlur(contentWithoutQuotes);
  };

  return (
    <ContentEditable
      tagName='p'
      html={html}
      onChange={handleChange}
      onBlur={handleBlur}
      className='w-full outline-none cursor-text whitespace-pre-wrap'
    />
  );
}
