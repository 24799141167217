import capitalize from 'lodash/capitalize';
import { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { usePreviousDistinct } from 'react-use';
import { $path } from 'remix-routes';
import { proxy, useSnapshot } from 'valtio';

import {
  EnumsRoleplayTurnEndMode,
  EnumsRoleplayVoice,
  type ModelsMediaAsset,
  type ModelsRoleplayEvaluationCriterion,
} from '@lp-lib/api-service-client/public';
import { MediaType, type RoleplayBlock } from '@lp-lib/game';

import { fromMediaDTO, toMediaDTO } from '../../../utils/api-dto';
import { uuidv4 } from '../../../utils/common';
import { MediaPickPriorityHD, MediaUtils } from '../../../utils/media';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { type Option } from '../../common/Utilities';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { ROLE_PLAY_SYSTEM_PROMPT } from '../../GameV2/blocks/Roleplay/Shared';
import { CommonButton } from '../../GameV2/design/Button';
import { EditableText } from '../../GameV2/design/Editable';
import { CloseIcon } from '../../icons/CloseIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { ImageIcon } from '../../icons/ImageIcon';
import { PauseIcon } from '../../icons/PauseIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import { useTriggerMediaSearchModal } from '../../MediaSearch/useTriggerMediaSearchModal';
import { SwitcherControlled } from '../../Switcher';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { useTrainingSlideEditor } from './hooks';
import { BlockIntroSelect } from './Shared/BlockIntroSelect';
import { type TrainingSlideEditorProps } from './types';

const tabs = ['intro', 'playing', 'success', 'failure', 'analysis'] as const;

type Tab = (typeof tabs)[number];

type State = {
  activeTab: Tab;
};

const state = proxy<State>({
  activeTab: 'intro',
});

export function RoleplayBlockEditorTabs() {
  const { activeTab } = useSnapshot(state);

  useEffect(() => {
    return () => {
      state.activeTab = 'intro';
    };
  }, []);

  return (
    <div className='flex items-center gap-2.5 text-white'>
      {tabs.map((tab) => (
        <button
          type='button'
          key={tab}
          className={`
            w-25 h-8 bg-secondary rounded-lg
            border ${activeTab === tab ? 'border-white' : 'border-transparent'}
          `}
          onClick={() => {
            state.activeTab = tab;
          }}
        >
          {capitalize(tab)}
        </button>
      ))}
    </div>
  );
}

function MediaField(props: {
  asset: ModelsMediaAsset | null;
  fullScreen: boolean;
  description?: string;
  onChange: (asset: ModelsMediaAsset | null) => void;
}) {
  const media = fromMediaDTO(props.asset?.media);
  const url = MediaUtils.PickMediaUrl(media, {
    priority: MediaPickPriorityHD,
  });
  const ref = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const triggerMediaSearchModal = useTriggerMediaSearchModal();
  const openMediaSearchModal = () => {
    triggerMediaSearchModal({
      video: true,
      onUploadSuccess: (media) => {
        const asset: ModelsMediaAsset = {
          media: toMediaDTO(media),
          data: {
            id: media.id,
          },
        };
        props.onChange(asset);
      },
    });
  };

  if (media && url) {
    return (
      <div className='w-full h-full relative group flex justify-center items-center'>
        {props.fullScreen ? (
          <div className='fixed inset-0'>
            {media.type === MediaType.Video && (
              <video
                src={url}
                className='w-full h-full object-cover'
                ref={ref}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
              />
            )}
            {media.type === MediaType.Image && (
              <img src={url} alt='' className='w-full h-full object-cover' />
            )}
          </div>
        ) : (
          <div className='w-full h-full flex justify-center items-center'>
            {media.type === MediaType.Video && (
              <video
                src={url}
                className='rounded-2.5xl object-contain'
                ref={ref}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
              />
            )}
            {media.type === MediaType.Image && (
              <img src={url} alt='' className='rounded-2.5xl object-contain' />
            )}
          </div>
        )}

        <div className='absolute flex items-center gap-2.5 group-hover:opacity-100 opacity-0 transition-opacity'>
          {media.type === MediaType.Video && (
            <button
              type='button'
              className='btn-secondary w-10 h-10 flex items-center justify-center'
              onClick={() => {
                if (isPlaying) {
                  ref.current?.pause();
                } else {
                  ref.current?.play();
                }
              }}
            >
              {isPlaying ? (
                <PauseIcon className='w-4 h-4 fill-current' />
              ) : (
                <PlayIcon className='w-4 h-4 fill-current' />
              )}
            </button>
          )}
          <button
            type='button'
            className='btn-secondary w-33 h-10'
            onClick={openMediaSearchModal}
          >
            Replace
          </button>
          <button
            type='button'
            onClick={() => props.onChange(null)}
            className='btn-secondary flex w-10 h-10 items-center justify-center text-red-002'
          >
            <DeleteIcon className='w-4 h-4 fill-current' />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='w-full h-full flex flex-col items-center justify-center gap-4'>
      <button
        type='button'
        className='
          w-full max-w-75 min-h-60 bg-main-layer hover:bg-light-gray transition-colors rounded-xl
          border border-secondary
          flex flex-col items-center justify-center gap-4
        '
        onClick={openMediaSearchModal}
      >
        <ImageIcon className='w-12 h-12 text-white' />
        <div className='text-center'>
          <div className='text-white'>Add Media</div>
        </div>
      </button>
      {props.description && (
        <p className='max-w-75 text-xs text-icon-gray'>{props.description}</p>
      )}
    </div>
  );
}

function Intro(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10'>
        <MediaField
          asset={props.block.fields.introMedia}
          fullScreen={props.block.fields.introMediaFullScreen}
          description='Upload media to automatically play. Last frame of video will be used throughout.'
          onChange={(asset) => {
            onChange('introMedia', asset);
            onBlur('introMedia', asset);
          }}
        />
      </div>

      <footer
        className={`mt-auto w-full flex items-center justify-center gap-2 p-3 pb-5`}
      >
        <CommonButton
          variant='correct'
          className='flex-none'
          styles={{
            disabled: 'disabled:opacity-100 disabled:cursor-auto',
          }}
          disabled
        >
          <EditableText
            value={props.block.fields.introButtonText || 'Start Conversation'}
            onBlur={(value) => {
              onChange('introButtonText', value);
              onBlur('introButtonText', value);
            }}
          />
        </CommonButton>
      </footer>
    </div>
  );
}

function IntroSidebar(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full flex flex-col gap-5 text-white'>
      <div>
        <p className='text-base font-bold mb-1'>Media Display</p>
        <div className='flex items-center justify-between'>
          <p className='text-sms'>Full screen</p>
          <SwitcherControlled
            name={`${props.block.id}-intro-fullScreen`}
            checked={props.block.fields.introMediaFullScreen}
            onChange={(checked: boolean): void => {
              onChange('introMediaFullScreen', checked);
              onBlur('introMediaFullScreen', checked);
            }}
          />
        </div>
      </div>
      <label>
        <p className='text-base text-white font-bold mb-1'>Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={props.block.fields.personalityId}
          isClearable
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Block Intro</p>
        <BlockIntroSelect
          value={props.block.fields.intro}
          onChange={(value) => {
            onChange('intro', value);
            onBlur('intro', value);
          }}
        />
      </label>
    </div>
  );
}

function Playing(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10'>
        <MediaField
          asset={props.block.fields.playingMedia}
          fullScreen={props.block.fields.playingMediaFullScreen}
          onChange={(asset) => {
            onChange('playingMedia', asset);
            onBlur('playingMedia', asset);
          }}
          description='Upload media to automatically play. Last frame of video will be used throughout.'
        />
      </div>

      {props.block.fields.turnEndMode ===
      EnumsRoleplayTurnEndMode.RoleplayTurnEndModePushToTalk ? (
        <footer
          className={`w-full p-3 pb-5 flex items-center justify-center gap-2`}
        >
          <CommonButton
            variant='gray'
            className='flex-none'
            styles={{
              disabled: 'disabled:opacity-100 disabled:cursor-auto',
              size: 'h-full',
            }}
            style={{
              aspectRatio: '1/1',
            }}
            disabled
          >
            <CloseIcon className='w-4 h-4 fill-current' />
          </CommonButton>

          <CommonButton
            variant='correct'
            className='flex-none'
            styles={{
              disabled: 'disabled:opacity-100 disabled:cursor-auto',
            }}
            disabled
          >
            Talk
          </CommonButton>
        </footer>
      ) : (
        <footer
          className={`mt-auto w-full flex items-center justify-center gap-2 p-3 pb-5`}
        >
          <CommonButton
            variant='incorrect'
            className='flex-none'
            styles={{
              disabled: 'disabled:opacity-100 disabled:cursor-auto',
            }}
            disabled
          >
            <EditableText
              value={props.block.fields.playingButtonText || 'End Conversation'}
              onBlur={(value) => {
                onChange('playingButtonText', value);
                onBlur('playingButtonText', value);
              }}
            />
          </CommonButton>
        </footer>
      )}
    </div>
  );
}

function VoiceSelect(props: {
  value: EnumsRoleplayVoice | null;
  onChange: (value: EnumsRoleplayVoice | null) => void;
}) {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<EnumsRoleplayVoice | null>>({
        override: {
          control: {
            height: '40px',
          },
        },
      }),
    []
  );

  const options = useMemo(() => {
    return Object.values(EnumsRoleplayVoice).map((value) => ({
      label: value,
      value,
    }));
  }, []);

  return (
    <Select<Option<EnumsRoleplayVoice | null>>
      className='w-full'
      classNamePrefix='select-box-v2'
      styles={styles}
      placeholder='Select a voice'
      options={options}
      value={props.value ? { label: props.value, value: props.value } : null}
      onChange={(value) => props.onChange(value?.value || null)}
    />
  );
}

function PlayingSidebar(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full flex flex-col gap-5 text-white'>
      <div>
        <p className='text-base font-bold mb-1'>Media Display</p>
        <div className='flex items-center justify-between'>
          <p className='text-sms'>Full screen</p>
          <SwitcherControlled
            name={`${props.block.id}-playing-fullScreen`}
            checked={props.block.fields.playingMediaFullScreen}
            onChange={(checked: boolean): void => {
              onChange('playingMediaFullScreen', checked);
              onBlur('playingMediaFullScreen', checked);
            }}
          />
        </div>
      </div>

      <div>
        <p className='text-base font-bold mb-1'>Voice</p>
        <VoiceSelect
          value={props.block.fields.voice}
          onChange={(value) => {
            onChange('voice', value);
            onBlur('voice', value);
          }}
        />
      </div>

      <div className='flex items-center justify-between'>
        <p className='text-base font-bold'>Auto Turn Detection</p>
        <SwitcherControlled
          name={`${props.block.id}-turn-detection`}
          checked={
            !props.block.fields.turnEndMode ||
            props.block.fields.turnEndMode ===
              EnumsRoleplayTurnEndMode.RoleplayTurnEndModeServerVad
          }
          onChange={(checked: boolean): void => {
            const mode = checked
              ? EnumsRoleplayTurnEndMode.RoleplayTurnEndModeServerVad
              : EnumsRoleplayTurnEndMode.RoleplayTurnEndModePushToTalk;
            onChange('turnEndMode', mode);
            onBlur('turnEndMode', mode);
          }}
        />
      </div>

      <label>
        <div className='mb-1 flex items-end justify-between'>
          <p className='text-base text-white font-bold'>System Prompt</p>
        </div>

        <textarea
          defaultValue={props.block.fields.systemPrompt}
          onChange={(e) => onChange('systemPrompt', e.currentTarget.value)}
          onBlur={(e) => onBlur('systemPrompt', e.currentTarget.value)}
          className='field mb-0 py-2 min-h-100'
          placeholder={ROLE_PLAY_SYSTEM_PROMPT}
        />

        <p className='mt-1 text-2xs text-secondary'>
          %scenePrompt%: the user-provided scenario.
        </p>
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>Scenario</p>
        <textarea
          defaultValue={props.block.fields.scenarioPrompt}
          onChange={(e) => onChange('scenarioPrompt', e.currentTarget.value)}
          onBlur={(e) => onBlur('scenarioPrompt', e.currentTarget.value)}
          className='field mb-0 py-2 min-h-100'
        />
      </label>
    </div>
  );
}

function Success(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10'>
        <MediaField
          asset={props.block.fields.successMedia}
          fullScreen={props.block.fields.successMediaFullScreen}
          onChange={(asset) => {
            onChange('successMedia', asset);
            onBlur('successMedia', asset);
          }}
          description='Upload media to automatically play when conversation is completed successfully'
        />
      </div>
    </div>
  );
}

function SuccessSidebar(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full flex flex-col gap-5 text-white'>
      <div>
        <p className='text-base font-bold mb-1'>Media Display</p>
        <div className='flex items-center justify-between'>
          <p className='text-sms'>Full screen</p>
          <SwitcherControlled
            name={`${props.block.id}-success-fullScreen`}
            checked={props.block.fields.successMediaFullScreen}
            onChange={(checked: boolean): void => {
              onChange('successMediaFullScreen', checked);
              onBlur('successMediaFullScreen', checked);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function Failure(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10'>
        <MediaField
          asset={props.block.fields.failureMedia}
          fullScreen={props.block.fields.failureMediaFullScreen}
          onChange={(asset) => {
            onChange('failureMedia', asset);
            onBlur('failureMedia', asset);
          }}
          description='Upload media to automatically play when conversation is completed unsuccessfully'
        />
      </div>
    </div>
  );
}

function FailureSidebar(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full flex flex-col gap-5 text-white'>
      <div>
        <p className='text-base font-bold mb-1'>Media Display</p>
        <div className='flex items-center justify-between'>
          <p className='text-sms'>Full screen</p>
          <SwitcherControlled
            name={`${props.block.id}-failure-fullScreen`}
            checked={props.block.fields.failureMediaFullScreen}
            onChange={(checked: boolean): void => {
              onChange('failureMediaFullScreen', checked);
              onBlur('failureMediaFullScreen', checked);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function EvaluationCriteriaModal(props: {
  criterion?: ModelsRoleplayEvaluationCriterion;
  onSave: (criterion: ModelsRoleplayEvaluationCriterion) => void;
  onCancel: () => void;
}) {
  const { criterion, onSave, onCancel } = props;
  const [name, setName] = useState(criterion?.name ?? '');
  const [prompt, setPrompt] = useState(criterion?.prompt ?? '');

  const handleSave = () => {
    onSave({
      id: criterion?.id ?? uuidv4(),
      name,
      prompt,
    });
  };

  return (
    <ModalWrapper
      containerClassName='w-160'
      innerClassName='rounded-xl'
      borderStyle='gray'
      onClose={onCancel}
    >
      <div className='w-full px-7.5 py-5 flex flex-col'>
        <header className='text-white flex flex-col gap-2 items-center'>
          <div className='text-2xl text-center'>
            {criterion ? 'Edit Evaluation Criteria' : 'Add Evaluation Criteria'}
          </div>
          <div className='w-3/4 text-center text-secondary text-sm'>
            Analyzes conversations by sending the transcript and custom prompt
            to the LLM for goal verification. The AI will return one of three
            results: success, failure, or unknown, along with a brief
            explanation of its decision.
          </div>
        </header>

        <div className='flex flex-col gap-1'>
          <label className='text-sm font-bold text-white'>Name</label>
          <input
            type='text'
            className='field'
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder='Deal Complete'
          />
        </div>

        <div className='flex flex-col gap-1'>
          <label className='text-sm font-bold text-white'>Prompt</label>
          <textarea
            className='field min-h-[120px] p-2'
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder='Evaluate if the conversation resulted in a successful deal'
          />
        </div>

        <footer className='mt-5 flex justify-center items-center gap-2'>
          <button
            type='button'
            className='btn-secondary w-30 h-10'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-30 h-10 mx-1 flex items-center justify-center gap-1'
            onClick={handleSave}
          >
            Save
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

function AnalysisSidebar(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { block } = props;
  const criteria = block.fields.evaluation?.criteria ?? [];
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleChangeCriteria = (
    criteria: ModelsRoleplayEvaluationCriterion[]
  ) => {
    const evaluation = {
      ...block.fields.evaluation,
      criteria,
    };
    onChange('evaluation', evaluation);
    onBlur('evaluation', evaluation);
  };

  const handleClickAdd = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <EvaluationCriteriaModal
          criterion={undefined}
          onSave={(criterion) => {
            const newCriteria = [...criteria, criterion];
            handleChangeCriteria(newCriteria);
            p.internalOnConfirm();
          }}
          onCancel={p.internalOnCancel}
        />
      ),
    });
  };

  const handleClickEdit = (criterion: ModelsRoleplayEvaluationCriterion) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <EvaluationCriteriaModal
          criterion={criterion}
          onSave={(updatedCriterion) => {
            const newCriteria = criteria.map((c) =>
              c.id === criterion.id ? updatedCriterion : c
            );
            handleChangeCriteria(newCriteria);
            p.internalOnConfirm();
          }}
          onCancel={p.internalOnCancel}
        />
      ),
    });
  };

  const handleDelete = async (id: string) => {
    const result = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <ConfirmCancelModalText className='text-2xl font-medium'>
          Are you sure you want to delete this evaluation criteria?
        </ConfirmCancelModalText>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'confirm',
    });
    if (result.result !== 'confirmed') return;

    const newCriteria = criteria.filter((c) => c.id !== id);
    handleChangeCriteria(newCriteria);
  };

  return (
    <div className='w-full flex flex-col gap-5 text-white'>
      <div>
        <div>
          <p className='text-base font-bold'>Evaluation Criteria</p>
          <p className='text-2xs text-secondary'>
            Define custom criteria to evaluate conversations against. You can
            find the evaluation results for each conversation{' '}
            <a
              href={$path('/roleplay/histories', {
                'block-id': props.block.id,
              })}
              className='text-primary underline'
              target='_blank'
              rel='noreferrer'
            >
              in the history tab
            </a>
            .
          </p>
        </div>
        {criteria.length > 0 && (
          <div className='mt-3 w-full flex flex-col gap-2'>
            {criteria.map((c) => (
              <div
                key={c.id}
                className='w-full bg-secondary p-2 rounded-lg flex justify-between items-center cursor-pointer gap-2 overflow-hidden'
                onClick={() => handleClickEdit(c)}
              >
                <div className='flex-1 flex flex-col overflow-hidden truncate'>
                  <span className='text-sms font-medium'>{c.name}</span>
                  <p className='text-xs text-secondary truncate'>{c.prompt}</p>
                </div>
                <button
                  type='button'
                  className='flex-none text-red-500'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(c.id);
                  }}
                >
                  <DeleteIcon className='w-4 h-4 fill-current' />
                </button>
              </div>
            ))}
          </div>
        )}
        <button
          type='button'
          className='mt-3 btn text-xs text-primary'
          onClick={handleClickAdd}
        >
          + Add Criteria
        </button>
      </div>
    </div>
  );
}

export function RoleplayBlockEditor(
  props: TrainingSlideEditorProps<RoleplayBlock>
) {
  const { activeTab } = useSnapshot(state);

  switch (activeTab) {
    case 'intro':
      return <Intro {...props} />;
    case 'playing':
      return <Playing {...props} />;
    case 'success':
      return <Success {...props} />;
    case 'failure':
      return <Failure {...props} />;
    case 'analysis':
      return null;
    default:
      return null;
  }
}

export function RoleplayBlockSidebarEditor(
  props: TrainingSlideEditorProps<RoleplayBlock>
) {
  const { activeTab } = useSnapshot(state);

  const prevActiveTab = usePreviousDistinct(activeTab);
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    if (!prevActiveTab) return;

    setShowOverlay(true);
    const timer = setTimeout(() => {
      setShowOverlay(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [activeTab, prevActiveTab]);

  let body = null;
  switch (activeTab) {
    case 'intro':
      body = <IntroSidebar {...props} />;
      break;
    case 'playing':
      body = <PlayingSidebar {...props} />;
      break;
    case 'success':
      body = <SuccessSidebar {...props} />;
      break;
    case 'failure':
      body = <FailureSidebar {...props} />;
      break;
    case 'analysis':
      body = <AnalysisSidebar {...props} />;
      break;
    default:
      body = null;
  }

  return (
    <>
      {body}
      {showOverlay && (
        <div className='absolute inset-0 bg-[#0029FF] opacity-40 rounded-xl'></div>
      )}
    </>
  );
}
