import { type RefObject, useMemo } from 'react';
import { useSnapshot } from 'valtio';

import { type DtoGamePack } from '@lp-lib/api-service-client/public';
import {
  type Block,
  BlockType,
  type DrawToWinBlock,
  type HiddenPictureBlock,
  type MemoryMatchBlock,
  type MultipleChoiceBlock,
  type QuestionBlock,
  type RoleplayBlock,
  type SlideBlock,
  type SlideBlockAnimationKey,
} from '@lp-lib/game';

import { type Game } from '../../../types/game';
import { BlockEditor } from '../../Game/Blocks/Common/Editor';
import { type GameEditorStore } from '../../Game/GameEditorStore';
import { type BlockAnimator } from '../../GameV2/apis/BlockAnimationControl';
import { StatusBar } from '../../GameV2/design/StatusBar';
import { BlockEditorStoreProvider } from '../../RoutedBlock';
import {
  DrawToWinBlockEditor,
  DrawToWinBlockSidebarEditor,
} from './DrawToWinBlockEditor';
import {
  HiddenPictureBlockEditor,
  HiddenPictureBlockSidebarEditor,
} from './HiddenPictureBlockEditor';
import { MatchBlockEditor, MatchBlockSidebarEditor } from './MatchBlockEditor';
import {
  MultipleChoiceBlockEditor,
  MultipleChoiceBlockSidebarEditor,
} from './MultipleChoiceBlockEditor';
import {
  QuestionBlockEditor,
  QuestionBlockSidebarEditor,
} from './QuestionBlockEditor';
import {
  RoleplayBlockEditor,
  RoleplayBlockEditorTabs,
  RoleplayBlockSidebarEditor,
} from './RoleplayBlockEditor';
import { SlideBlockEditor, SlideBlockSidebarEditor } from './SlideBlockEditor';
import { SlideGroupEditor } from './SlideGroupEditor';

export function TrainingEditorDetailTabs(props: { store: GameEditorStore }) {
  const { store } = props;
  const { selectedBlockId } = useSnapshot(store.state);
  const { blocks } = useSnapshot(store.blockEditorStore.state);
  const selectedBlock = useMemo(() => {
    if (!selectedBlockId) return null;
    return blocks.find((b) => b.id === selectedBlockId);
  }, [blocks, selectedBlockId]);

  switch (selectedBlock?.type) {
    case BlockType.ROLEPLAY:
      return <RoleplayBlockEditorTabs key={selectedBlockId} />;
    default:
      return null;
  }
}

export function TrainingEditorDetail(props: {
  pack: DtoGamePack;
  stores: GameEditorStore[];
  selectedGameId: string | null;
  store: GameEditorStore;
  animator: BlockAnimator;
}) {
  const { store } = props;
  const { game, selectedBlockId } = useSnapshot(store.state);
  const { blocks } = useSnapshot(store.blockEditorStore.state);
  const [selectedBlock, progressPct] = useMemo(() => {
    const selectedBlockIndex = blocks.findIndex(
      (b) => b.id === selectedBlockId
    );
    const selectedBlock =
      selectedBlockIndex === -1 ? null : blocks[selectedBlockIndex];
    const progressPct =
      selectedBlockIndex === -1
        ? 0
        : (selectedBlockIndex / blocks.length) * 100;
    return [selectedBlock, progressPct];
  }, [blocks, selectedBlockId]);

  const setSavingChanges = () => console.log('saving changes');
  return (
    <BlockEditorStoreProvider store={store.blockEditorStore}>
      <div className='flex-1 h-full overflow-auto scrollbar'>
        {selectedBlock ? (
          <BlockEditorLayout progressPct={progressPct}>
            {selectedBlock.type === BlockType.SLIDE ? (
              <SlideBlockEditor
                key={selectedBlock.id}
                block={selectedBlock as SlideBlock}
                setSavingChanges={setSavingChanges}
                animator={
                  props.animator as BlockAnimator<SlideBlockAnimationKey>
                }
              />
            ) : selectedBlock.type === BlockType.QUESTION ? (
              <QuestionBlockEditor
                key={selectedBlock.id}
                block={selectedBlock as QuestionBlock}
                setSavingChanges={setSavingChanges}
              />
            ) : selectedBlock.type === BlockType.MULTIPLE_CHOICE ? (
              <MultipleChoiceBlockEditor
                key={selectedBlock.id}
                block={selectedBlock as MultipleChoiceBlock}
                setSavingChanges={setSavingChanges}
              />
            ) : selectedBlock.type === BlockType.MEMORY_MATCH ? (
              <MatchBlockEditor
                key={selectedBlock.id}
                block={selectedBlock as MemoryMatchBlock}
                setSavingChanges={setSavingChanges}
              />
            ) : selectedBlock.type === BlockType.ROLEPLAY ? (
              <RoleplayBlockEditor
                key={selectedBlock.id}
                block={selectedBlock as RoleplayBlock}
                setSavingChanges={setSavingChanges}
              />
            ) : selectedBlock.type === BlockType.DRAW_TO_WIN ? (
              <DrawToWinBlockEditor
                key={selectedBlock.id}
                block={selectedBlock as DrawToWinBlock}
                setSavingChanges={setSavingChanges}
              />
            ) : selectedBlock.type === BlockType.HIDDEN_PICTURE ? (
              <HiddenPictureBlockEditor
                key={selectedBlock.id}
                block={selectedBlock as HiddenPictureBlock}
                setSavingChanges={setSavingChanges}
              />
            ) : (
              <div className='w-full h-full px-5'>
                <BlockEditor
                  block={selectedBlock as Block}
                  setSavingChanges={setSavingChanges}
                />
              </div>
            )}
          </BlockEditorLayout>
        ) : game ? (
          <SlideGroupEditor
            pack={props.pack}
            stores={props.stores}
            selectedGame={game as Game}
          />
        ) : null}
      </div>
    </BlockEditorStoreProvider>
  );
}

export function TrainingEditorDetailSidebar(props: {
  store: GameEditorStore;
  animator: BlockAnimator;
  devicePreview: RefObject<HTMLIFrameElement>;
}) {
  const { store } = props;
  const { selectedBlockId } = useSnapshot(store.state);
  const { blocks } = useSnapshot(store.blockEditorStore.state);
  const selectedBlock = useMemo(() => {
    if (!selectedBlockId) return null;
    return blocks.find((b) => b.id === selectedBlockId);
  }, [blocks, selectedBlockId]);
  const setSavingChanges = () => console.log('saving changes');

  if (!selectedBlock) return null;

  return (
    <BlockEditorStoreProvider store={store.blockEditorStore}>
      {selectedBlock.type === BlockType.SLIDE ? (
        <SlideBlockSidebarEditor
          key={selectedBlock.id}
          block={selectedBlock as SlideBlock}
          setSavingChanges={setSavingChanges}
          animator={props.animator as BlockAnimator<SlideBlockAnimationKey>}
          devicePreview={props.devicePreview}
        />
      ) : selectedBlock.type === BlockType.QUESTION ? (
        <QuestionBlockSidebarEditor
          key={selectedBlock.id}
          block={selectedBlock as QuestionBlock}
          setSavingChanges={setSavingChanges}
        />
      ) : selectedBlock.type === BlockType.MULTIPLE_CHOICE ? (
        <MultipleChoiceBlockSidebarEditor
          key={selectedBlock.id}
          block={selectedBlock as MultipleChoiceBlock}
          setSavingChanges={setSavingChanges}
        />
      ) : selectedBlock.type === BlockType.MEMORY_MATCH ? (
        <MatchBlockSidebarEditor
          key={selectedBlock.id}
          block={selectedBlock as MemoryMatchBlock}
          setSavingChanges={setSavingChanges}
        />
      ) : selectedBlock.type === BlockType.ROLEPLAY ? (
        <RoleplayBlockSidebarEditor
          key={selectedBlock.id}
          block={selectedBlock as RoleplayBlock}
          setSavingChanges={setSavingChanges}
        />
      ) : selectedBlock.type === BlockType.DRAW_TO_WIN ? (
        <DrawToWinBlockSidebarEditor
          key={selectedBlock.id}
          block={selectedBlock as DrawToWinBlock}
          setSavingChanges={setSavingChanges}
        />
      ) : selectedBlock.type === BlockType.HIDDEN_PICTURE ? (
        <HiddenPictureBlockSidebarEditor
          key={selectedBlock.id}
          block={selectedBlock as HiddenPictureBlock}
          setSavingChanges={setSavingChanges}
        />
      ) : null}
    </BlockEditorStoreProvider>
  );
}

function BlockEditorLayout(props: {
  progressPct: number;
  children: React.ReactNode;
}) {
  return (
    <div className='relative w-full max-w-240 h-full min-h-0 mx-auto flex flex-col'>
      <div className='p-5 pb-0 z-5'>
        <StatusBar progressPct={props.progressPct} />
      </div>
      <div className='flex-1 min-h-0 overflow-hidden'>{props.children}</div>
    </div>
  );
}
