import { type CSSProperties, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import { useInstance } from '../../../hooks/useInstance';
import { getAgentInfo } from '../../../utils/user-agent';

export function EditableText(props: {
  value: string;
  onBlur: (value: string) => void;
  className?: string;
  placeholder?: string;
  style?: object | CSSProperties;
}) {
  const { value, onBlur } = props;

  const [html, setHtml] = useState(() => {
    return value || '';
  });

  return (
    <ContentEditable
      tagName='p'
      html={html}
      onChange={(e) => {
        setHtml(e.currentTarget.innerHTML);
      }}
      onBlur={(e) => {
        let text = e.currentTarget.innerText;
        if (props.placeholder) {
          if (text.trim() === '') {
            text = '';
            setHtml(text);
          }
        }
        onBlur(text);
      }}
      className={
        props.className ?? 'w-full outline-none cursor-text whitespace-pre-wrap'
      }
      data-placeholder={props.placeholder}
      style={props.style}
    />
  );
}

/**
 * If your field component uses text-center or placeholder, you may want to use
 * FFriendlyEditableText instead. Firefox has a few issues with ContentEditable,
 * see the demo for more info.
 * https://codesandbox.io/p/sandbox/content-editable-ff-w8g7v7
 */
export function FFriendlyEditableText(props: {
  value: string;
  onBlur: (value: string) => void;
  className?: string;
  placeholder?: string;
  style?: object | CSSProperties;
}) {
  const { value, onBlur } = props;
  const agentInfo = useInstance(() => getAgentInfo());

  const [html, setHtml] = useState(() => {
    return value || '';
  });

  if (!agentInfo.browser.isFirefox) return <EditableText {...props} />;

  return (
    <ContentEditable
      tagName='p'
      html={html}
      onChange={(e) => {
        setHtml(e.currentTarget.innerHTML);
      }}
      onBlur={(e) => {
        const text = e.currentTarget.innerText.trim();
        e.currentTarget.innerHTML = text;
        setHtml(text);
        onBlur(text);
      }}
      className={
        props.className ?? 'w-full outline-none cursor-text whitespace-pre-wrap'
      }
      onFocus={(e) => {
        if (e.currentTarget.innerText === '') {
          e.currentTarget.innerHTML = ' ';
        }
      }}
      data-placeholder={props.placeholder}
      style={props.style}
    />
  );
}
